// Dependencies
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";

import * as go from "gojs";
import classNames from "classnames";
// import ZoomSlider  from "gojs/extensions/ZoomSlider.js";
import { ReactDiagram, ReactPalette } from "gojs-react";
import { internalization } from "../../constants.js"
import { canManage, canRead, convertClasses } from "../../helpers/permits";

import { gsap } from "gsap";


import {deleteCookie, getCookie, setCookie} from '../../helpers/cookie'
import Modal from "../../components/modal";
import Input from "../../components/input";
import SelectMultiple from "../../components/selectMultiple";
import ModalInfo from "../../components/goJs/modalInfo";
import ModalInfoEs from "../../components/goJs/modalInfoEs";
import AlertModal from "../../components/alertModal";

// Items
import mainItem from "../../components/goJs/mainItem";
import trustItem from "../../components/goJs/trustItem";
import corporationItem from "../../components/goJs/corporationItem";
import investmentItem from "../../components/goJs/investmentItem";
import liabilityItem from "../../components/goJs/liabilityItem";
import divisionItem from "../../components/goJs/divisionItem";
import commentItem from "../../components/goJs/commentItem";
import otherItem from "../../components/goJs/otherItem";

// Images
import general from "../../assets/images/goJs/slider/general.png";

import Icon from "../../components/icons";

var languageUserAgent = /^es/.test(navigator.language || navigator.userLanguage || "en") ? "es" : "en";

var diagram;

// Colors
const colors = {
  orange: "#EA9323",
  greyDark: "#89898A",
  greyMedium: "#E4E4E4",
  greyDarkMedium: "#D4D5D9",
  blueDark: "#005774",
  blueLight: "#80ABBA",
  blueLighter: "#A3C0E0",
  red: "#ff4d4d",
  greenLight: "#e6eb76",
};

const temp_paletes = {
  tools: {
    title: "Tools",
    elements: [
      {
        key: 1,
        category: "Comment",
        title: "Add comment",
        isPalette: "true",
      },
      {
        key: 2,
        category: "Division",
        title: "Add a Division",
        isPalette: "true",
      },
      {
        key: 3,
        category: "Other",
        title: "Add a Other",
        isPalette: "true",
      },
    ],
  },
  ownership: {
    title: "Ownership Structures",
    elements: [
      {
        key: 1,
        category: "Corporation",
        title: "Select Corporation",
        isPalette: "true",
      },
      {
        key: 2,
        category: "Trust",
        title: "Select Trust",
        isPalette: "true",
      },
      {
        key: 3,
        category: "Investment",
        investmentType: "InvestmentAccount",
        title: "Select Investment",
        isPalette: "true",
      },
    ],
  },
  assets: {
    title: "Assets",
    elements: [
      {
        key: 1,
        category: "Investment",
        investmentType: "Vehicle",
        title: "Select Vehicle",
        isPalette: "true",
      },
      {
        key: 2,
        category: "Investment",
        investmentType: "RealEstate",
        title: "Select Real Estate",
        isPalette: "true",
      },
      {
        key: 3,
        category: "Investment",
        investmentType: "Art",
        title: "Select Art",
        isPalette: "true",
      },
      {
        key: 4,
        category: "Investment",
        investmentType: "BankAccount",
        title: "Select Bank Account",
        isPalette: "true",
      },
      {
        key: 5,
        category: "Investment",
        investmentType: "ReceivableAccount",
        title: "Select Receivable Account",
        isPalette: "true",
      },
      {
        key: 6,
        category: "Investment",
        investmentType: "LifeInsurance",
        title: "Select Life Insurance",
        isPalette: "true",
      },
      {
        key: 7,
        category: "Investment",
        investmentType: "PrivateEquity",
        title: "Select Direct Investment",
        isPalette: "true",
      },
    ],
  },
  liabilities: {
    title: "Liabilities",
    elements: [
      {
        key: 1,
        category: "Liability",
        title: "Select Liability",
        isPalette: "true",
      },
    ],
  },
};

let isEditTemp = false
const GojsCanvas = (props) => {
  const [originDiagram, setOriginDiagram] = useState(null);
  // const [numOfModifications, setNumOfModifications] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [structure, setStructure] = useState(props.ownership_structure);
  const [isSaved, setIsSaved] = useState(false);
  const [nodeInserted, setNodeInserted] = useState(null);
  const [showEditModal, setShowEditModal] = useState({});
  const [showCommentModal, setShowCommentModal] = useState({});
  const [showUserModal, setShowUserModal] = useState({});
  const [showAutolayoutModal, setShowAutolayoutModal] = useState({});
  const [showManuallayoutModal, setShowManuallayoutModal] = useState({});
  
  const [showDeleteModal, setShowDeleteModal] = useState({});
  const [enableAutomaticLayout, setEnableAutomaticLayout] = useState(true);
  const [isEmpty, setIsEmpty] = useState(true);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [paletteTabActive, setPaletteTabActive] = useState([]);
  const [paletteContent, setPaletteContent] = useState([]);

  const [showNewModal, setShowNewModal] = useState({});
  const [selectOptionSelected, setSelectOptionSelected] = useState(null);

  const [editTitle, setEditTitle] = useState("");
  const [editSurname, setEditSurname] = useState("");

  const [savingText, setSavingText] = useState("");

  const [modalData, setModalData] = useState({});
  const [showAmount, setShowAmount] = useState(getCookie("showAmount") || "true");

  const [currentSelected, setCurrentSelected] = useState([]);
  const [currentNodeDataArray, setCurrentNodeDataArray] = useState(props.ownership_structure.nodeDataArray);

  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const buttonsDropdownRef = useRef()
  const goJsButtonsWrapperRef = useRef();

  const [topWrapper, setTopWrapper] = useState(0);
  const [leftWrapper, setLeftWrapper] = useState(0);
  const [widthWrapper, setWidthWrapper] = useState(0);
  const [heightWrapper, setHeightWrapper] = useState(0);

  const [topButtons, setTopButtons] = useState(0);
  const [leftButtons, setLeftButtons] = useState(0);
  const [widthButtons, setWidthButtons] = useState(0);
  const [heightButtons, setHeightButtons] = useState(0);
 
  useEffect(() => {

    if(getCookie("showAmount") === ""){
      setCookie("showAmount", showAmount, 1)  
    }

  }, [])

  useEffect(() => {

    if(gojsWrapper.current) {
      setTopWrapper(gojsWrapper.current.getBoundingClientRect().top)
      setLeftWrapper(gojsWrapper.current.getBoundingClientRect().left)
      setWidthWrapper(gojsWrapper.current.getBoundingClientRect().width)
      setHeightWrapper(gojsWrapper.current.getBoundingClientRect().height)
    }

    if(goJsButtonsWrapperRef.current) {
      setTopButtons(goJsButtonsWrapperRef.current.getBoundingClientRect().top)   
      setLeftButtons(goJsButtonsWrapperRef.current.getBoundingClientRect().left)  
      setWidthButtons(goJsButtonsWrapperRef.current.getBoundingClientRect().width) 
      setHeightButtons(goJsButtonsWrapperRef.current.getBoundingClientRect().height)
    }

  }, []) 


  useEffect(() => {
    if (props.ownership_structure !== undefined) {
      props.ownership_structure.nodeDataArray.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
      setStructure(props.ownership_structure);
      if (props.location && props.location.state && props.location.state.is_ownership_linked) {
        try {
          setTimeout(() => {
            let node_find = props.ownership_structure.nodeDataArray.filter(
              (obj) =>
                obj.class_name == props.location.state.class && obj.id == props.location.state.id
            );
            if (node_find.length > 0) {
              console.log("tofocus");
              let node_to_focus = diagram.findNodeForKey(node_find[0].key);
              focusNode(node_to_focus, true);
              let state = { ...props.history.location.state };
              delete state.is_ownership_linked;
              props.history.replace({ ...props.history.location, state });
            }
          }, 1000);
        } catch (e) {
          console.log(e);
        }
      }

      if (
        props.isHome !== true &&
        props.isMobile !== true &&
        Object.keys(props.ownership_structure).length == 0
      ) {
        setTimeout(() => {
          onEdit();
        }, 500);
      }

      // if (props.isHome === true) {
      //   setTimeout(() => {
      //     // diagram.commandHandler.zoomToFit()
      //   }, 700);
      // }
    }
  }, [props.ownership_structure]);

  useEffect(() => {
    if (props.isHome !== true && props.ownership_structure.nodeDataArray.length === 0) {
      setTimeout(() => {
        if (isEmpty) {
          const canvasMessage = document.querySelector(".c-gojs__diagram-message");
          const palette = document.querySelector(".c-gojs__palette-tabs");

          canvasMessage.style.maxWidth = "78%";
          palette.style.minWidth = "20%";
          palette.style.marginLeft = "2%";
          palette.addEventListener("click", () => {
            onEdit();
          });
        }
      }, 1000);
    }
  }, [props.ownership_structure]);

  useEffect(() => {
    if (props.removeLastElement) {
      removeLastNode();
    }
  }, [props.removeLastElement]);
  
  useEffect(() => {
    isEditTemp = isEdit
  }, [isEdit]);
  
  const gojsWrapper = useRef(null);
  const diagramRef = useRef(null);
  const tabsRef = useRef(null);
  const newSelectRef = useRef(null);

  const handlePaletteTabs = (index, length) => {
    const paletteTabs = tabsRef.current.querySelectorAll(".c-gojs__palette-tabs--item");
    // if palette item is open (height is bigger than 36)
    // close it and go out
    if (paletteTabs[index].offsetHeight > 36) {
      paletteTabs[index].style.height = "36px";
      return false;
    }

    // close every item
    closeTabs();

    // open selected item
    paletteTabs[index].style.height = `${length * 49 + 49 - 10}px`;
  };

  const isEditButtonClasses = () => {
    let cl = classNames("btn-circle-icon", isEmpty && "o-animation-blink");
    return cl;
  };

  const closeTabs = () => {
    if (tabsRef.current) {
      let tabs = tabsRef.current.querySelectorAll(".c-gojs__palette-tabs--item");
      tabs.forEach((tab) => {
        tab.style.height = "36px";
      });
    }
  };

  // Create needed GraphObject
  // we'll use $ for consciseness
  const $ = go.GraphObject.make;

  const blueDarkOpacity = "rgba(0, 87,116, 0.5)";

  /**
   * Diagram
   */

  const showLinkLabel = (e) => {
    var label = e.subject.findObject("LABEL");
    if (label !== null) label.visible = e.subject.fromNode.data.category === "Conditional";
  };

  // R is a Rect in document coordinates
  // NODE is the Node being moved -- ignore when looking for Parts intersecting the Rect
  // R is a Rect in document coordinates
  // NODE is the Node being moved -- ignore when looking for Parts intersecting the Rect
  const isUnoccupied = (r, node) => {
    var diagram = node.diagram;

    // nested function used by Layer.findObjectsIn, below
    // only consider Parts, and ignore the given Node, any Links, and Group members
    function navig(obj) {
      var part = obj.part;
      if (part === node) return null;
      if (part instanceof go.Link) return null;
      if (part.isMemberOf(node)) return null;
      if (node.isMemberOf(part)) return null;
      return part;
    }

    // only consider non-temporary Layers
    var lit = diagram.layers;
    while (lit.next()) {
      var lay = lit.value;
      if (lay.isTemporary) continue;
      if (lay.findObjectsIn(r, navig, null, true).count > 0) return false;
    }
    return true;
  };

  const showAmountFunction = () => {
    // console.log("cookie1", getCookie("showAmount"))
    // console.log("cookie", getCookie("showAmount") === "true")
    return getCookie("showAmount") === "true"
  }
  // a Part.dragComputation function that prevents a Part from being dragged to overlap another Part
  // use PT instead of GRIDPT if DraggingTool.isGridSnapEnabled but movement should not snap to grid
  const avoidNodeOverlap = (node, pt, gridpt) => {
    if (node.diagram instanceof go.Palette) return gridpt;
    // this assumes each node is fully rectangular
    var bnds = node.actualBounds;
    var loc = node.location;
    // use PT instead of GRIDPT if you want to ignore any grid snapping behavior
    // see if the area at the proposed location is unoccupied
    var r = new go.Rect(
      gridpt.x - (loc.x - bnds.x),
      gridpt.y - (loc.y - bnds.y),
      bnds.width,
      bnds.height
    );
    // maybe inflate R if you want some space between the node and any other nodes
    r.inflate(-0.5, -0.5); // by default, deflate to avoid edge overlaps with "exact" fits
    // when dragging a node from another Diagram, choose an unoccupied area
    if (
      !(node.diagram.currentTool instanceof go.DraggingTool) &&
      (!node._temp || !node.layer.isTemporary)
    ) {
      // in Temporary Layer during external drag-and-drop
      node._temp = true; // flag to avoid repeated searches during external drag-and-drop
      while (!isUnoccupied(r, node)) {
        r.x += 2; // note that this is an unimaginative search algorithm --
        r.y += 2; // you can improve the search here to be more appropriate for your app
      }
      r.inflate(0.5, 0.5); // restore to actual size
      // return the proposed new location point
      return new go.Point(r.x - (loc.x - bnds.x), r.y - (loc.y - bnds.y));
    }
    if (isUnoccupied(r, node)) return gridpt; // OK
    return loc; // give up -- don't allow the node to be moved to the new location
  };
  const initDiagram = () => {
    // Create our diagram for ReactDiagram
    // with initial options
    diagram = $(go.Diagram, {
      padding: 30,
      initialDocumentSpot: go.Spot.Top,
      initialViewportSpot: go.Spot.Top,
      initialAutoScale: go.Diagram.Uniform,
      autoScale:
        props.isHome === true && props.isMobile !== true ? go.Diagram.Uniform : go.Diagram.None,
      mouseWheelBehavior:
        props.isHome === true && props.isMobile !== true
          ? go.ToolManager.WheelNone
          : go.ToolManager.WheelScroll,
      isReadOnly: props.isHome === true && props.isMobile !== true ? true : false,
      allowSelect: props.isHome === true && props.isMobile !== true ? false : true,
      "panningTool.isEnabled": props.isHome === true && props.isMobile !== true ? false : true,
      "grid.visible": false,
      "grid.gridCellSize": new go.Size(5, 5),
      // "draggingTool.isGridSnapEnabled": true,
      "resizingTool.isGridSnapEnabled": true,
      LinkDrawn: showLinkLabel, // this DiagramEvent listener is defined below
      LinkRelinked: showLinkLabel,
      "undoManager.isEnabled": true, // must be set to allow for model change listening
      doubleClick: function (e) {
        var diag = e.diagram;
        var pt = diag.lastInput.documentPoint;
        var sc = 1; // target scale
        var w = (diag.viewportBounds.width * diag.scale) / sc;
        var h = (diag.viewportBounds.height * diag.scale) / sc;
        var pos = new go.Point(pt.x - w / 2, pt.y - h / 2); // target position
        var anim = new go.Animation();
        anim.easing = go.Animation.EaseLinear;
        anim.add(diag, "scale", diag.scale, sc);
        anim.add(diag, "position", diag.position, pos);
        anim.start();
      },
      "commandHandler.canDeleteSelection": function (e) {
        console.log("commandHandler.canDeleteSelection", isEditTemp);
        if(!isEditTemp){
          return
        }
        let to_find = currentSelected[currentSelected.length - 1]
        let node = diagram.findLinkForKey(to_find);
        diagram.model.commit(function (m) {
          let node = m.linkDataArray.filter((e) => e.key === to_find)[0];
          setCurrentSelected([]);
          m.removeLinkData(node);
        }, "delete link");
        return false;
        // return !this.diagram.selection.any(function(p) { return p.data.key.indexOf("e") >= 0; })
        //         && go.CommandHandler.prototype.canDeleteSelection.call(this);
      },
      // "commandHandler.deleteSelection": function() {
      //   var items = findAllSelectedItems();
      //   if (items.length > 0) {  // if there are any selected items, delete them
      //     myDiagram.startTransaction("delete items");
      //     for (var i = 0; i < items.length; i++) {
      //       var panel = items[i];
      //       var nodedata = panel.part.data;
      //       var itemarray = nodedata.fields;
      //       var itemdata = panel.data;
      //       var itemindex = itemarray.indexOf(itemdata);
      //       myDiagram.model.removeArrayItem(itemarray, itemindex);
      //     }
      //     myDiagram.commitTransaction("delete items");
      //   } else {  // otherwise just delete nodes and/or links, as usual
      //     go.CommandHandler.prototype.deleteSelection.call(myDiagram.commandHandler);
      //   }
      // };
      InitialLayoutCompleted: function (e) {
        // if not all Nodes have real locations, force a layout to happen
        if (
          !e.diagram.nodes.all(function (n) {
            return n.location.isReal();
          })
        ) {
          props.currentAutomaticLayout && e.diagram.layoutDiagram(true);
        }
      },
    });

    /*
     *  Establece un layout y reordena los elementos
     */
    //FIRST TIME AFTER IMPORTING AUTOMÁTICALLY SHOULD BE THIS ENABLED
    // if(!props.ownership_structure.hasOwnProperty("updated") && enableAutomaticLayout){
    diagram.layout = $(go.LayeredDigraphLayout, {
      // columnSpacing: 24,
      layerSpacing: 90,
      direction: 90,
      // setsPortSpots: false,
      isInitial: props.currentAutomaticLayout, //automati true or false
      isOngoing: false,
      layeringOption: go.LayeredDigraphLayout.LayerLongestPathSource
      // initializeOption: go.LayeredDigraphLayout.InitNaive
    });
    // }
    //

    // A model holds the essential information for each node and each link
    if(props.currentAutomaticLayout){
      diagram.model = $(go.GraphLinksModel, {
      linkKeyProperty: "key", // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
    });
    }else{
      diagram.model = $(go.GraphLinksModel, {
      linkKeyProperty: "key", // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
      linkFromPortIdProperty: "fromPort",
      linkToPortIdProperty: "toPort",

    });
    }
    
    // diagram.isEnabled= true;
    // diagram.model.isReadOnly = true;

    /**
     * Diagram built-in events
     */
    diagram.addDiagramListener("Modified", (e, obj) => {
      // diagram.isEnabled= false;
      // diagram.alignDocument(go.Spot.Center, go.Spot.Center)
    });
    diagram.addDiagramListener("ChangedSelection", handleOnDiagramEvent);
    /**
     * @Diagram built-in events
     */

    /**
     * ITEMS DESIGN
     */

    // DEFAULT ITEMS
    commentItem(diagram, editButtonClick, nodeStyle, commentStyle, makePort, colors);

    mainItem(
      diagram,
      editButtonClick,
      goButtonClick,
      nodeStyle,
      titleStyle,
      textStyle,
      makePort,
      colors,
      isEdit,
      props.avatar,
      avoidNodeOverlap
    );

    trustItem(
      diagram,
      collapseButtonClick,
      editButtonClick,
      goButtonClick,
      nodeStyle,
      badgeStyle,
      titleStyle,
      textStyle,
      makePort,
      colors,
      avoidNodeOverlap,
      canReadElement
    );

    corporationItem(
      diagram,
      collapseButtonClick,
      editButtonClick,
      goButtonClick,
      nodeStyle,
      badgeStyle,
      titleStyle,
      textStyle,
      makePort,
      colors,
      avoidNodeOverlap,
      canReadElement
    );

    investmentItem(
      diagram,
      collapseButtonClick,
      editButtonClick,
      goButtonClick,
      nodeStyle,
      badgeStyle,
      titleStyle,
      textStyle,
      makePort,
      colors,
      avoidNodeOverlap,
      showAmountFunction,
      canReadElement
    );

    liabilityItem(
      diagram,
      collapseButtonClick,
      editButtonClick,
      goButtonClick,
      nodeStyle,
      badgeStyle,
      titleStyle,
      textStyle,
      makePort,
      colors,
      avoidNodeOverlap,
      canReadElement
    );

    divisionItem(
      diagram,
      editButtonClick,
      nodeStyle,
      makePort,
      colors,
      textStyle,
      avoidNodeOverlap
    );

    otherItem(
      diagram,
      editButtonClick,
      nodeStyle,
      badgeStyle,
      titleStyle,
      textStyle,
      makePort,
      colors,
      avoidNodeOverlap
    );


    // HERE WE CUSTOMIZE EACH CANVAS ITEM
    diagram.nodeTemplateMap.add(
      "Start",
      $(
        go.Node,
        "Table",
        nodeStyle(),
        $(
          go.Panel,
          "Spot",
          $(go.Shape, "Circle", {
            desiredSize: new go.Size(70, 70),
            fill: "#cecece",
            stroke: "#09d3ac",
            strokeWidth: 3.5,
          }),
          $(go.TextBlock, "Start", textStyle(), new go.Binding("text"))
        ),
        // three named ports, one on each side except the top, all output only:
        makePort("T", go.Spot.Top, go.Spot.Top, false, true),
        makePort("L", go.Spot.Left, go.Spot.Left, true, false),
        makePort("R", go.Spot.Right, go.Spot.Right, true, false),
        makePort("B", go.Spot.Bottom, go.Spot.Bottom, true, false)
      )
    );

    diagram.nodeTemplateMap.add(
      "End",
      $(
        go.Node,
        "Table",
        nodeStyle(),
        $(
          go.Panel,
          "Spot",
          $(go.Shape, "Circle", {
            desiredSize: new go.Size(60, 60),
            fill: "#cecece",
            stroke: "#DC3C00",
            strokeWidth: 3.5,
          }),
          $(go.TextBlock, "End", textStyle(), new go.Binding("text"))
        ),
        // three named ports, one on each side except the bottom, all input only:
        makePort("T", go.Spot.Top, go.Spot.Top, false, true),
        makePort("L", go.Spot.Left, go.Spot.Left, false, true),
        makePort("R", go.Spot.Right, go.Spot.Right, false, true),
        makePort("B", go.Spot.Bottom, go.Spot.Bottom, true, false)
      )
    );

    // HERE WE DEFINE A CUSTOM SHAPE AS FILE
    go.Shape.defineFigureGenerator("File", (shape, w, h) => {
      var geo = new go.Geometry();
      var fig = new go.PathFigure(0, 0, true); // starting point
      geo.add(fig);
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.75 * w, 0));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, 0.25 * h));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, h).close());
      var fig2 = new go.PathFigure(0.75 * w, 0, false);
      geo.add(fig2);
      // The Fold
      fig2.add(new go.PathSegment(go.PathSegment.Line, 0.75 * w, 0.25 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Line, w, 0.25 * h));
      geo.spot1 = new go.Spot(0, 0.25);
      geo.spot2 = go.Spot.BottomRight;
      return geo;
    });
    // HERE WE CUSTOMIZE FILE CANVAS ITEM WITH ITS CUSTOM SHAPE
    // diagram.nodeTemplateMap.add('Comment',
    //   $(go.Node, "Auto", nodeStyle(),
    //     $(go.Shape, "File",
    //       { fill: "#cecece", stroke: "#DEE0A3", strokeWidth: 3 }),
    //     $(go.TextBlock, textStyle(),
    //       {
    //         margin: 8,
    //         maxSize: new go.Size(200, NaN),
    //         wrap: go.TextBlock.WrapFit,
    //         textAlign: "center",
    //         editable: true
    //       },
    //       new go.Binding("text").makeTwoWay())
    //     // no ports, because no links are allowed to connect with a comment
    //   )
    // );

    /**
     * @ITEMS DESIGN
     */

    diagram.linkTemplate = $(
      go.Link, // the whole link panel
      {
        routing: go.Link.AvoidsNodes,
        curve: go.Link.JumpOver,
        corner: 8,
        toShortLength: 4,
        // relinkableFrom: true,
        // relinkableTo: true,
        reshapable: true,
        resegmentable: true,
        // mouse-overs subtly highlight links:
        mouseEnter: function (e, link) {
          link.findObject("HIGHLIGHT").stroke = "rgba(30,144,255,0.2)";
        },
        mouseLeave: function (e, link) {
          link.findObject("HIGHLIGHT").stroke = "transparent";
        },
        selectionAdornmentTemplate: $(
          go.Adornment,
          $(go.Shape, {
            isPanelMain: true,
            stroke: colors.blueDark,
            strokeWidth: 4,
          }),
          $(go.Shape, {
            toArrow: "Standard",
            fill: colors.blueDark,
            stroke: null,
            scale: 2.5,
          })
        ), // end Adornment
      },
      // new go.Binding("points").makeTwoWay(),
      $(
        go.Shape, // the highlight shape, normally transparent
        {
          isPanelMain: true,
          strokeWidth: 8,
          stroke: "transparent",
          name: "HIGHLIGHT",
        }
      ),
      $(
        go.Shape, // the link path shape
        { isPanelMain: true, stroke: colors.greyDark, strokeWidth: 2 },
        new go.Binding("stroke", "isSelected", function (sel) {
          return sel ? colors.orange : colors.greyDark;
        }).ofObject()
      ),
      $(
        go.Shape, // the arrowhead
        { toArrow: "standard", strokeWidth: 0, fill: colors.greyDark }
      )
      // $(go.Panel, "Auto",  // the link label, normally not visible
      //   { visible: false, name: "LABEL", segmentIndex: 2, segmentFraction: 0.5 },
      //   new go.Binding("visible", "visible").makeTwoWay(),
      //   $(go.Shape, "RoundedRectangle",  // the label shape
      //     { fill: "#F8F8F8", strokeWidth: 0 }),
      //   $(go.TextBlock, "Yes",  // the label
      //     {
      //       textAlign: "center",
      //       font: "10pt helvetica, arial, sans-serif",
      //       stroke: "#333333",
      //       editable: true
      //     },
      //     new go.Binding("text").makeTwoWay())
      // )
    );

    // Make link labels visible if coming out of a "conditional" node.
    // This listener is called by the "LinkDrawn" and "LinkRelinked" DiagramEvents.

    // temporary links used by LinkingTool and RelinkingTool are also orthogonal:
    diagram.toolManager.linkingTool.temporaryLink.routing = go.Link.Orthogonal;
    diagram.toolManager.relinkingTool.temporaryLink.routing = go.Link.Orthogonal;

    var AllowTopLevel = false;
    var CellSize = new go.Size(50, 50);

    function highlightGroup(grp, show) {
      if (!grp) return false;
      // check that the drop may really happen into the Group
      var tool = grp.diagram.toolManager.draggingTool;
      grp.isHighlighted = show && grp.canAddMembers(tool.draggingParts);
      return grp.isHighlighted;
    }

    var groupFill = "rgba(128,128,128,0.2)";
    var groupStroke = "gray";
    var dropFill = "rgba(128,255,255,0.2)";
    var dropStroke = "red";

    diagram.groupTemplate = $(
      go.Group,
      {
        layerName: "Background",
        resizable: true,
        resizeObjectName: "SHAPE",
        // because the gridSnapCellSpot is Center, offset the Group's location
        locationSpot: new go.Spot(0, 0, CellSize.width / 2, CellSize.height / 2),
      },
      // always save/load the point that is the top-left corner of the node, not the location
      new go.Binding("position", "pos", go.Point.parse).makeTwoWay(go.Point.stringify),
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
      {
        // what to do when a drag-over or a drag-drop occurs on a Group
        mouseDragEnter: (e, grp, prev) => {
          if (!highlightGroup(grp, true)) e.diagram.currentCursor = "not-allowed";
          else e.diagram.currentCursor = "";
        },
        mouseDragLeave: (e, grp, next) => highlightGroup(grp, false),
        mouseDrop: (e, grp) => {
          var ok = grp.addMembers(grp.diagram.selection, true);
          if (!ok) grp.diagram.currentTool.doCancel();
        },
      },
      $(
        go.Shape,
        "Rectangle", // the rectangular shape around the members
        {
          name: "SHAPE",
          fill: groupFill,
          stroke: groupStroke,
          minSize: new go.Size(CellSize.width * 2, CellSize.height * 2),
        },
        new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(go.Size.stringify),
        new go.Binding("fill", "isHighlighted", (h) => (h ? dropFill : groupFill)).ofObject(),
        new go.Binding("stroke", "isHighlighted", (h) => (h ? dropStroke : groupStroke)).ofObject()
      )
    );

    // decide what kinds of Parts can be added to a Group
    diagram.commandHandler.memberValidation = (grp, node) => {
      if (grp instanceof go.Group && node instanceof go.Group) return false; // cannot add Groups to Groups
      // but dropping a Group onto the background is always OK
      return true;
    };
    // load();  // load an initial diagram from some JSON text

    setOriginDiagram(diagram);
    props.setDiagram(diagram);
    return diagram;
  };

  // Define a function for creating a "port" that is normally transparent.
  // The "name" is used as the GraphObject.portId,
  // the "align" is used to determine where to position the port relative to the body of the node,
  // the "spot" is used to control how links connect with the port and whether the port
  // stretches along the side of the node,
  // and the boolean "output" and "input" arguments control whether the user can draw links from or to the port.
  const makePort = (name, align, spot, output, input) => {
    var horizontal = align.equals(go.Spot.Top) || align.equals(go.Spot.Bottom);
    // the port is basically just a transparent rectangle that stretches along the side of the node,
    // and becomes colored when the mouse passes over it
    return $(go.Shape, {
      fill: "transparent", // changed to a color in the mouseEnter event handler
      strokeWidth: 0, // no stroke
      width: horizontal ? NaN : 8, // if not stretching horizontally, just 8 wide
      height: !horizontal ? NaN : 8, // if not stretching vertically, just 8 tall
      alignment: align, // align the port on the main Shape
      stretch: horizontal ? go.GraphObject.Horizontal : go.GraphObject.Vertical,
      portId: name, // declare this object to be a "port"
      fromSpot: spot, // declare where links may connect at this port
      fromLinkable: output, // declare whether the user may draw links from here
      toSpot: spot, // declare where links may connect at this port
      toLinkable: input, // declare whether the user may draw links to here
      cursor: "pointer", // show a different cursor to indicate potential link point
      mouseEnter: function (e, port) {
        // the PORT argument will be this Shape
        if (!e.diagram.isReadOnly) port.fill = blueDarkOpacity;
      },
      mouseLeave: function (e, port) {
        port.fill = "transparent";
      },
    });
  };

  // helper definitions for node templates
  const nodeStyle = () => {
    return [
      // The Node.location comes from the "loc" property of the node data,
      // converted by the Point.parse static method.
      // If the Node.location is changed, it updates the "loc" property of the node data,
      // converting back using the Point.stringify static method.
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
      {
        locationSpot: go.Spot.Center,
        selectionAdorned: true, // remove blue outlines when focusing it
        selectionAdornmentTemplate: $(
          go.Adornment,
          "Auto",
          $(go.Shape, "RoundedRectangle", {
            // fill: null,
            // stroke: colors.blueLight,
            // strokeWidth: 8,
            strokeWidth: 0,
            parameter1: 18,
            fill: colors.greyMedium,
            opacity: 0.25,
          }),
          $(go.Placeholder)
        ), // end Adornment
      },
    ];
  };

  // This is a re-implementation of the default animation, except it fades in from downwards, instead of upwards.
  const animateFadeDown = (e) => {
    var diagram = e.diagram;
    var animation = new go.Animation();
    animation.isViewportUnconstrained = true; // So Diagram positioning rules let the animation start off-screen
    animation.easing = go.Animation.EaseOutExpo;
    animation.duration = 900;
    // Fade "down", in other words, fade in from above
    animation.add(diagram, "position", diagram.position.copy().offset(0, 200), diagram.position);
    animation.add(diagram, "opacity", 0, 1);
    animation.start();
  };

  /**
   * Palette
   */
  // initialize the Palette that is on the left side of the page
  const initPalette = () => {
    const palette = $(go.Palette, {
      // Instead of the default animation, use a custom fade-down
      "animationManager.initialAnimationStyle": go.AnimationManager.None,
      // "InitialAnimationStarting": animateFadeDown, // Instead, animate with this function

      nodeTemplateMap: originDiagram.nodeTemplateMap, // share the templates used by myDiagram
      groupTemplate: originDiagram.groupTemplate, // share the templates used by myDiagram
      // model: new go.GraphLinksModel()
    });
    return palette;
  };

  const initPaletteTwo = () => {
    // initialize the second Palette, of tall items
    const myPaletteTall = $(go.Palette, {
      // share the templates with the main Diagram
      // Instead of the default animation, use a custom fade-down
      "animationManager.initialAnimationStyle": go.AnimationManager.None,
      nodeTemplateMap: originDiagram.nodeTemplateMap, // share the templates used by myDiagram
      groupTemplate: originDiagram.groupTemplate,
    });
    return myPaletteTall;
  };

  const initPaletteThree = () => {
    // initialize the second Palette, of tall items
    const myPaletteTall = $(go.Palette, {
      // share the templates with the main Diagram
      // Instead of the default animation, use a custom fade-down
      "animationManager.initialAnimationStyle": go.AnimationManager.None,
      nodeTemplateMap: originDiagram.nodeTemplateMap, // share the templates used by myDiagram
      groupTemplate: originDiagram.groupTemplate,
    });
    return myPaletteTall;
  };

  /**
   * Text Styles
   */
  const commentStyle = () => {
    return {
      font: "14px Averta-Regular",
      stroke: colors.blueDark,
      alignment: go.Spot.TopLeft,
      margin: new go.Margin(32, 8, 8, 8),
      maxSize: new go.Size(200, NaN),
      minSize: new go.Size(32, NaN),
    };
  };

  const textStyle = () => {
    return {
      font: "14px Averta-Regular",
      stroke: colors.blueDark,
      editable: false,
      wrap: go.TextBlock.WrapFit,
      alignment: go.Spot.Left,
      maxSize: new go.Size(220, 18),
      margin: new go.Margin(0, 0, 4, 0),
    };
  };

  const titleStyle = () => {
    return {
      font: "14px Averta-Bold",
      stroke: colors.blueDark,
      editable: false,
      wrap: go.TextBlock.WrapFit,
      alignment: go.Spot.Left,
      // width: 148,
      margin: new go.Margin(0, 0, 4, 0),
      maxSize: new go.Size(148, NaN),
    };
  };

  const badgeStyle = () => {
    return {
      font: "13px Averta-Regular",
      stroke: "#fff",
      verticalAlignment: go.Spot.Center,
      textAlign: "center",
      width: 24,
      height: 24,
    };
  };

  useEffect(() => {
    if (diagramRef.current) {
      let ownershipParent = diagramRef.current.divRef.current.closest(".ownership");

      gojsWrapper.current.style.minHeight = `${ownershipParent.offsetHeight - 200}px`;

      const diagram = diagramRef.current.getDiagram();
      if (diagram instanceof go.Diagram) {
        // ...
        diagram.isReadOnly = !isEdit;
      }
    }
  }, [gojsWrapper, diagramRef, isEdit]);

  /**
   * Events
   */
  const onEdit = () => {


   showDropdownMenu && setShowDropdownMenu(!showDropdownMenu);


    setIsEmpty(false);

    const canvasMessage = document.querySelector(".c-gojs__diagram-message");
    const canvas = document.querySelector(".c-gojs__diagram");
    const palette = document.querySelector(".c-gojs__palette-tabs");
    if (canvas && palette) {
      if (!isEdit) {
        if (canvasMessage) {
          canvasMessage.style.width = "78%";
        }
        canvas.style.minWidth = "78%";
        palette.style.minWidth = "20%";
        palette.style.marginLeft = "2%";

        // palette.style.minWidth = "24%";
        // palette.style.paddingLeft = "32px";
        setTimeout(() => {
          setIsEdit(true);
          initDiagram.isEnabled = true;
        }, 500);
      } else {
        if (canvasMessage) {
          canvasMessage.style.minWidth = "100%";
        }
        if(props.currentAutomaticLayout){
          diagram.layoutDiagram(true);
        }
        canvas.style.width = "100%";
        palette.style.minWidth = "0";
        palette.style.marginLeft = "0";
        
        // palette.style.paddingLeft = "0px";
        setTimeout(() => {
          initDiagram.isEnabled = false;
          closeTabs();
          setIsEdit(false);
        }, 500);
      }
    }
  };

  // const onSave = (changes) => {
  //   if (structure !== undefined) {
  //     let result = JSON.parse(diagram.model.toJson());
  //     console.log("currentNodeDataArray", currentNodeDataArray)
  //     // changes.hasOwnProperty("removedNodeKeys") && props.ownership_structure.nodeDataArray == 1
  //
  //     if (result.nodeDataArray.length > 0 || (changes.hasOwnProperty("removedNodeKeys") && currentNodeDataArray && currentNodeDataArray.length === 1)) {
  //       setSavingText("Saving...");
  //       setIsSaved(true);
  //       props.saveOwnershipStructure(
  //         {
  //           ownership_structure: result,
  //           changes: changes,
  //         },
  //         (responseJSON) => {setCurrentNodeDataArray(responseJSON.ownership_structure.nodeDataArray);console.log("success", responseJSON.ownership_structure);setSavingText("Saved")}
  //       );
  //       // setStructure(result)
  //       global.sessKeyPressed && global.sessKeyPressed();
  //       if(props.isHome === true){ setTimeout(() => diagram.requestUpdate(), 500)}
  //     }
  //   }
  //
  //   // document.getElementById("mySavedModel").value = initDiagram.model.toJson();
  //   // initDiagram.isModified = false;
  // };

  const load = () => {
    initDiagram.model = go.Model.fromJson(props.ownership_structure);
  };

  const deleteElement = (node_to_delete) => {
    diagram.model.commit(function (m) {
      let node = m.nodeDataArray.filter((e) => e.key === node_to_delete.key)[0];
      let n = diagram.findNodeForKey(node.key);
      diagram.selectCollection(n.findLinksConnected());
      diagram.commandHandler.deleteSelection();
      m.removeNodeData(node);
    }, "delete node");
    setEditTitle("");
    // diagram.layoutDiagram(true);
    setShowDeleteModal({});
    setShowEditModal({});
    setTimeout(() => props.getPalettes(), 1500);
  };

  const collapseButtonClick = (e, button) => {
    alert("collapse it!");
  };

  const canReadElement = (class_name) => {
    return canRead(props.permits, convertClasses(class_name))
  }

  const editButtonClick = (e, node) => {
    if(props.can_manage === false) return;
    if (props.isMobile === true) return;
    setShowEditModal({ node: node.part.data });
    setTimeout(() => prepareEditInputs(node), 100);
  };

  const prepareEditInputs = (node) => {
    setEditTitle(node.part.data.title || node.part.data.name);
    if (node.part.data.class_name === "User") {
      setEditSurname(node.part.data.surname);
    }
  };
  const goButtonClick = (e, button) => {
    if (props.isMobile === true) return;
    try {
      window.location.href = "/" + button.part.data["url"];
    } catch (e) {
      window.location.reload();
    }
  };
  /**
   * @Events
   */

  // print the diagram by opening a new window holding SVG images of the diagram contents for each page
  const printDiagram = () => {
    var svgWindow = window.open();
    if (!svgWindow) return; // failure to open a new Window
    var printSize = new go.Size(700, 960);
    var bnds = initDiagram.documentBounds;
    var x = bnds.x;
    var y = bnds.y;
    while (y < bnds.bottom) {
      while (x < bnds.right) {
        var svg = initDiagram.makeSvg({
          scale: 1.0,
          position: new go.Point(x, y),
          size: printSize,
        });
        svgWindow.document.body.appendChild(svg);
        x += printSize.width;
      }
      x = bnds.x;
      y += printSize.height;
    }
    setTimeout(function () {
      svgWindow.print();
    }, 1);
  };

  const focusNode = (node_to_focus, edit = false) => {
    if (node_to_focus !== null) {
      setTimeout(() => {
        // diagram.centerRect(node_to_focus.actualBounds);
        diagram.select(node_to_focus);
        initDiagram.isEnabled = true;
      }, 800);
    }
  };

  const handleModelChange = (changes) => {
    // let save = true;
    console.log({ changes });
    // console.log("Diagram changed!", changes);
    if (changes.hasOwnProperty("insertedNodeKeys")) {
      setNodeInserted(changes);
      // Al añadir item al canvas comprobamos si es investment
      // para levantar modal
      //controlar que no es la primera vez pq si no salta siempre
      if (
        props.isNew == false &&
        changes["insertedNodeKeys"].length === 1 &&
        changes.hasOwnProperty("modifiedNodeData") &&
        Object.keys(showNewModal).length === 0
      ) {
        
        if (
          props.ownership_structure.nodeDataArray &&
          props.ownership_structure.nodeDataArray.length === 1 &&
          changes.modifiedNodeData[0].id === props.ownership_structure.nodeDataArray[0].id
        ) {
          return;
        }
        loadSelectionModal(changes);
        // save = false;
      }
    } else if (changes.hasOwnProperty("removedNodeKeys")) {
      console.log("removing node", changes);
    } else if (changes.hasOwnProperty("insertedLinkKeys")) {
      console.log("new link", changes);
      let modifiedLinkData = changes["modifiedLinkData"];
      var n = diagram.findNodeForKey(modifiedLinkData[0]["from"]);
      var n2 = diagram.findNodeForKey(modifiedLinkData[0]["to"]);
      if (n.findLinksBetween(n2).count > 1) {
        // diagram.startTransaction("remove link");
        // diagram.remove(link);
        // diagram.commitTransaction("remove link");
        diagram.model.commit(function (m) {
          try {
            let node = m.linkDataArray.filter((e) => e.key === modifiedLinkData[0]["key"])[0];
            m.removeLinkData(node);
          } catch (e) {
            console.log("error removing");
          }
        }, "delete link");
        return;
      }
    } else {
      console.log("moving element", changes);
    }
    if (props.isNew) {
      
      if (
        changes.hasOwnProperty("insertedNodeKeys") &&
        changes["insertedNodeKeys"].length === 1 &&
        changes.hasOwnProperty("modifiedNodeData") &&
        Object.keys(showNewModal).length === 0
      ) {
        // diagram.model.commit(function(m) {
        //   m.addNodeData(changes["modifiedNodeData"][0])
        // }, "add node");
        diagram.layout.invalidateLayout();
        diagram.requestUpdate();
        diagram.layoutDiagram(true);

        setTimeout(() => {
          let to_element = changes["insertedNodeKeys"].length > 1 ? changes["insertedNodeKeys"][1] : changes["insertedNodeKeys"][0]
          let node_to_focus = diagram.findNodeForKey(changes["insertedNodeKeys"][0]);
          
          console.log("focus new");
          focusNode(node_to_focus);
        }, 300);
      } else if(currentNodeDataArray.length + 1 === props.ownership_structure.nodeDataArray.length){
        let node_to_focus = diagram.findNodeForKey(props.ownership_structure.nodeDataArray[props.ownership_structure.nodeDataArray.length -1].key);        
        focusNode(node_to_focus);
      } else {
        if (changes["insertedNodeKeys"] && changes["insertedNodeKeys"].length > 0) {
          let node_to_focus = diagram.findNodeForKey(changes["insertedNodeKeys"][0]);
          if (props.location && props.location.state && props.location.state.is_ownership_linked) {
            //no go
          } else {
            focusNode(node_to_focus);
          }
        }
      }
    }

    // if (save) {
    //   onSave(changes);
    // }

    // En cada modificación aumentamos
    // para avisar al user si se olvidó
    // de guardar
    // setNumOfModifications(changes)
  };

  const checkInvestmentType = (changes, isInvestmentType) => {
    // if (isInvestmentType) {
    //  const isInvestmentType = changes.modifiedNodeData[0].hasOwnProperty("investmentType")
    //   const investmentType = changes.modifiedNodeData[0].investmentType
    // }
    // setShowNewModal()
  };

  const handleOnDiagramEvent = (e) => {
    console.log("handleOnDiagramEvent", e);
    for (let it = diagram.selection.iterator; it.next(); ) {
      let node = it.value; // part is now a Node or a Group or a Link or maybe a simple Part
      console.log(node.key);
      let t = currentSelected;
      t.push(node.key);
      setCurrentSelected(currentSelected);
    }
  };

  const _createAssetFromModal = (node) => {
    props.showModalCreation(props.inputs_creation[node.class_name]);
    setSelectOptionSelected(null);
    setShowNewModal({});
  };

  const loadSelectionModal = (changes) => {
    let node_key = changes["insertedNodeKeys"][0];
    let node = changes["modifiedNodeData"].filter((node) => node.key == node_key)[0];

    // console.log("tocall", node.class_name);
    if (node.class_name !== "User") {
      if (node.class_name === "WildCardStructure") {
        setShowEditModal({ node: node, element: {} });
      } else if (node.class_name === "Comment") {
        setShowCommentModal({ node: node });
      } else {
        props.getListElement(
          node.class_name,
          (responseJSON) => {
            setShowNewModal({ elements: responseJSON, node: node });
          },
          () => console.log("error")
        );
      }
    } else {
      setShowUserModal({ node: node });
      setEditTitle(node.name);
    }

    //retrieve all investmentType for the user.
    //call

    setModalData(changes);
  };
  const sendNewForm = () => {
    let temp_structure = structure;
    // diagram.commit(function(d) {
    //   var node = d.findNodeForKey(showNewModal.node.key)
    // // have the Model add a new node data
    //   d.model.removeNodeData(node);
    //   // and then add a link data connecting the original node with the new one
    // }, "remove node");
    let new_node = Object.assign(showNewModal.node, {
      title: selectOptionSelected.value,
      id: selectOptionSelected.id,
      url: selectOptionSelected.url,
      amount: selectOptionSelected.amount,
    });

    delete new_node.isPalette;

    // diagram.commit(function(d) {
    // // have the Model add a new node data
    //   d.model.addNodeData(new_node);  // this makes sure the key is unique
    //   // and then add a link data connecting the original node with the new one
    // }, "add node");
    diagram.model.commit(function (m) {
      let node = m.nodeDataArray.filter((e) => e.key === showNewModal.node.key)[0];
      m.set(node, "id", selectOptionSelected.id);
      m.set(node, "title", selectOptionSelected.value);
      m.set(node, "url", selectOptionSelected.url);
      m.set(node, "amount", selectOptionSelected.amount);
      m.set(node, "isPalette", false);
    }, "update node");
    let key_to_go = showNewModal.node.key;
    setStructure(temp_structure);
    setShowNewModal({});
    setSelectOptionSelected({});
    // setStructure
    setTimeout(() => {
      let node_to_focus = diagram.findNodeForKey(key_to_go);
      console.log("focus updated");
      focusNode(node_to_focus);
    }, 500);
  };
  const cancelEditForm = () => {
    if (
      showEditModal.node.class_name === "WildCardStructure" &&
      showEditModal.node.isPalette == "true"
    ) {
      removeLastNode();
    }
    setEditTitle("");
    setShowEditModal({});
  };

  const renderEditModal = () => {
    let title = "" + props.internalization.buttons.edit + " " + showEditModal.node.title;
    if (showEditModal.node.class_name === "User") {
      title = "Edit Wealth Map Head";
    }
    return (
      <Modal
        buttonText={props.internalization.buttons.back}
        showBackButton={true}
        backAction={cancelEditForm}
      >
        <div className="wrapper-form">
          <div className="wrapper-form__title">
            <p className="headingH2">{title}</p>
          </div>

          <div className="wrapper-form-item">
            <span className="input-label">Name</span>
            <Input
              onChangeInput={(e) => setEditTitle(e.target.value)}
              value={editTitle}
              type="text"
              name="title"
              onFocus={true}
              onBlur={true}
              defaultValue={editTitle}
              errorLabel={"Este campo no puede estar vacío"}
            />
          </div>

          {/*<div className="wrapper-form-item">
            <span className="input-label">Cantidad</span>
            <Input
              onChangeInput={e => setInvestmentAmoutValue(e.target.value)}
              value={editTitle}
              type='text'
              name="amount"
              onFocus={true}
              onBlur={true}
              errorLabel={"Este campo no puede estar vacío"}
            />
          </div>*/}
          <div className="flex wrapper-tabs o-padding--bottom-zero">
            <button
              type="submit"
              className="btn secondary"
              onClick={() => setShowDeleteModal(showEditModal.node)}
            >
              <span className="btn-text">{props.internalization.buttons.remove}</span>
            </button>

            <button
              type="submit"
              className="btn primary"
              onClick={() => sendEditForm()}
              disabled={editTitle === ""}
            >
              <span className="btn-text">{props.internalization.buttons.save}</span>
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const sendEditForm = () => {
    if (
      showEditModal.node.class_name !== "WildCardStructure" &&
      showEditModal.node.class_name !== "Comment" &&
      showEditModal.node.class_name !== "User"
    ) {
      props.editElement(
        {
          title: editTitle,
          id: showEditModal.node.id,
          type: showEditModal.node.class_name,
        },
        () => successEditAsset(),
        () => errorEditAsset()
      );
    } else if (showEditModal.node.class_name === "Comment") {
      saveComment(showEditModal);
    } else if (showEditModal.node.class_name === "User") {
      diagram.model.commit(function (m) {
        let node = m.nodeDataArray.filter((e) => e.key === showEditModal.node.key)[0];
        m.set(node, "name", editTitle);
      }, "edit node user");
      setEditTitle("");
      setEditSurname("");
      setShowEditModal({});
    } else {
      props.createWildCardStructure(
        { name: editTitle, type: showEditModal.node.category },
        (responseJSON) => {
          successCreateWildStructure(responseJSON);
        },
        () => errorCreateWildStructure()
      );
    }
  };

  const successEditAsset = () => {
    diagram.model.commit(function (m) {
      let node = m.nodeDataArray.filter((e) => e.key === showEditModal.node.key)[0];
      m.set(node, "title", editTitle);
      m.set(node, "name", editTitle);
    }, "update node");
    let key_to_go = showEditModal.node.key;
    setTimeout(() => {
      let node_to_focus = diagram.findNodeForKey(key_to_go);
      console.log("focus edit succcess");
      focusNode(node_to_focus);
    }, 500);

    setEditTitle("");
    setShowEditModal({});
  };
  const errorEditAsset = () => {};
  const successCreateWildStructure = (responseJSON) => {
    diagram.model.commit(function (m) {
      let node = m.nodeDataArray.filter((e) => e.key === showEditModal.node.key)[0];
      m.set(node, "id", responseJSON.id);
      m.set(node, "title", responseJSON.title);
      m.set(node, "url", "");
      m.set(node, "isPalette", false);
    }, "update node");
    let key_to_go = showEditModal.node.key;
    setTimeout(() => {
      let node_to_focus = diagram.findNodeForKey(key_to_go);
      console.log("focus create wild");
      focusNode(node_to_focus);
    }, 500);
    setEditTitle("");
    setShowEditModal({});
  };

  const errorCreateWildStructure = () => {
    alert("Something went wrong");
    diagram.model.commit(function (m) {
      try {
        let node = m.nodeDataArray.filter((e) => e.key === showEditModal.node.key)[0];
        m.removeNodeData(node);
      } catch (e) {
        console.log("error removing");
      }
    }, "delete node");
    setSelectOptionSelected(null);
    setShowNewModal({});
  };
  const cancelNewForm = () => {
    //remove current element
    diagram.model.commit(function (m) {
      try {
        let node = m.nodeDataArray.filter((e) => e.key === showNewModal.node.key)[0];
        m.removeNodeData(node);
      } catch (e) {
        console.log("error removing");
      }
    }, "delete node");
    setSelectOptionSelected(null);
    setShowNewModal({});
  };

  const removeLastNode = () => {
    diagram.model.commit(function (m) {
      try {
        let node = m.nodeDataArray[m.nodeDataArray.length - 1];
        m.removeNodeData(node);
      } catch (e) {
        console.log("error removing");
      }
    }, "delete node");
  };

  const renderNewModal = () => {
    console.log({showNewModal})
    // comprobamos si en un investment para coger el tipo
    // checkInvestmentType(changes)

    return (
      <Modal
        buttonText={props.internalization.buttons.back}
        showBackButton={true}
        backAction={cancelNewForm}
      >
        <div className="wrapper-form">
          <div className="wrapper-form__title">
            <p className="headingH2">{showNewModal.node.title}</p>
          </div>

          <div className="wrapper-form-item" style={{ height: "auto" }}>
            <div>
              <p>{props.internalization.wealth_map.select_existent}</p>
              {showNewModal.elements.length > 0 && (
                <SelectMultiple
                  setRef={(ref) => newSelectRef}
                  defaultValue={selectOptionSelected ? selectOptionSelected.value : props.internalization.wealth_map.select}
                  colorGrey={true}
                  label={showNewModal.node.title}
                  options={showNewModal.elements}
                  multiple={false}
                  getOptions={(options) => setSelectOptionSelected(options.item)}
                  openOptions={() => console.log("opened!")}
                  optionsNoAbsolute={true}
                />
              )}
              {showNewModal.elements.length === 0 && <p>All elements are already used</p>}
            </div>
            <hr />
            {!["Account", "LiabilityAddepar"].includes(showNewModal.node.class_name) && (
              <div style={{ marginTop: 10, display: "flex" }}>
                <p style={{ marginRight: 16 }}>{props.internalization.wealth_map.create_a_new_one}</p>
                <button onClick={() => _createAssetFromModal(showNewModal.node)}>
                  <Icon
                    name="Close"
                    color="white"
                    iconSmall={true}
                    className="wrapper-icon-floatButton"
                    close={false}
                    width={10}
                    height={10}
                  />
                </button>
              </div>
            )}
          </div>

          <button
            type="submit"
            className="btn primary"
            onClick={() => sendNewForm()}
            disabled={!selectOptionSelected}
          >
            <span className="btn-text">{props.internalization.buttons.save}</span>
          </button>
        </div>
      </Modal>
    );
  };
  const renderDeleteModal = () => {
    let text = "";

    if (["Trust", "Corporation"].includes(showDeleteModal.class_name)) {
      text = props.internalization.alerts.remove_ownership_confirmation;
    } else if (["Liability"].includes(showDeleteModal.class_name)) {
      text = props.internalization.alerts.remove_liability_confirmation;
    } else {
      text = props.internalization.alerts.remove_asset_confirmation;
    }
    let title = showDeleteModal.title;
    if (showDeleteModal.class_name === "User") {
      title = "Wealth Map Head";
    }
    return (
      <Modal
        buttonText={props.internalization.buttons.back}
        showBackButton={true}
        backAction={() => setShowDeleteModal({})}
      >
        <div className="wrapper-form">
          <div className="wrapper-form__title">
            <p className="headingH2">
              {text}
              {"\n"}({title})
            </p>
          </div>

          <div className="flex wrapper-tabs o-padding--bottom-zero">
            <button type="submit" className="btn primary" onClick={() => setShowDeleteModal({})}>
              <span className="btn-text">{props.internalization.buttons.cancel}</span>
            </button>

            <button
              type="submit"
              className="btn secondary"
              onClick={() => deleteElement(showDeleteModal)}
              disabled={editTitle === ""}
            >
              <span className="btn-text">{props.internalization.buttons.remove_from_canvas}</span>
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const cancelCommentModal = () => {
    removeLastNode();
    setEditTitle("");
    setShowCommentModal({});
    setShowUserModal({});
  };

  const saveComment = (object) => {
    diagram.model.commit(function (m) {
      let node = m.nodeDataArray.filter((e) => e.key === object.node.key)[0];
      m.set(node, "title", editTitle);
      m.set(node, "name", editTitle);
      m.set(node, "isPalette", false);
    }, "update node");
    setEditTitle("");
    setShowCommentModal({});
    setShowEditModal({});
  };

  const saveUser = (object) => {
    diagram.model.commit(function (m) {
      let node = m.nodeDataArray.filter((e) => e.key === object.node.key)[0];
      m.set(node, "isPalette", false);
      m.set(node, "name", editTitle);
    }, "update node");
    setEditTitle("");
    setShowUserModal({});
    setTimeout(() => props.getPalettes(), 1500);
  };

  const changeAutoLayout = () => {
    props.updateAutomaticLayout(!props.currentAutomaticLayout)
  }

  const renderUserModal = () => {
    return (
      <Modal
        buttonText={props.internalization.buttons.back}
        showBackButton={true}
        backAction={cancelCommentModal}
      >
        <div className="wrapper-form">
          <div className="wrapper-form__title">
            <p className="headingH2">Adding Wealth Map Head</p>
          </div>

          <div className="wrapper-form-item">
            <span className="input-label">Name</span>
            <div>
              <Input
                onChangeInput={(e) => setEditTitle(e.target.value)}
                value={editTitle}
                type="text"
                name="title"
                onFocus={true}
                onBlur={true}
                defaultValue={editTitle}
                errorLabel={"Este campo no puede estar vacío"}
              />
            </div>
          </div>
          <div className="flex wrapper-tabs o-padding--bottom-zero">
            <button
              type="submit"
              className="btn secondary"
              onClick={() => setShowDeleteModal(showUserModal.node)}
            >
              <span className="btn-text">{props.internalization.buttons.remove}</span>
            </button>

            <button
              type="submit"
              className="btn primary"
              onClick={() => saveUser(showUserModal)}
              disabled={editTitle === ""}
            >
              <span className="btn-text">{props.internalization.buttons.add}</span>
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  const renderCommentModal = () => {
    return (
      <Modal
        buttonText={props.internalization.buttons.back}
        showBackButton={true}
        backAction={cancelCommentModal}
      >
        <div className="wrapper-form">
          <div className="wrapper-form__title">
            <p className="headingH2">
              {props.internalization.buttons.edit} {showCommentModal.node.title}
            </p>
          </div>

          <div className="wrapper-form-item">
            <span className="input-label">Text</span>
            <div>
              <Input
                onChangeInput={(e) => setEditTitle(e.target.value)}
                value={editTitle}
                type="text"
                name="title"
                onFocus={true}
                onBlur={true}
                defaultValue={editTitle}
                errorLabel={"Este campo no puede estar vacío"}
              />
            </div>
          </div>
          <div className="flex wrapper-tabs o-padding--bottom-zero">
            <button
              type="submit"
              className="btn secondary"
              onClick={() => setShowDeleteModal(showCommentModal.node)}
            >
              <span className="btn-text">{props.internalization.buttons.remove}</span>
            </button>

            <button
              type="submit"
              className="btn primary"
              onClick={() => saveComment(showCommentModal)}
              disabled={editTitle === ""}
            >
              <span className="btn-text">{props.internalization.buttons.save}</span>
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const renderAutoLayoutChangeModal = () => {
    return(
      <AlertModal
        isShowed={true}
        title={props.internalization.alerts.wealth_map_autolayout_title}
        titleRegular={true}
        msg={props.currentAutomaticLayout 
          ? props.internalization.alerts.wealth_map_manuallayout_description
          : props.internalization.alerts.wealth_map_autolayout_description
        }
        msgBold={false}
        action={() => {changeAutoLayout()}}
        textButton={props.internalization.buttons.confirm}
        textButtonAlertColor={false}
        cancelButton={true}
        cancelTextButton={props.internalization.buttons.cancel}
        cancelButtonAlertColor={true}
        actionCancelButton={() => setShowAutolayoutModal({})}
        maxHeight={'auto'}
      />
    )
  }

  const handleSwitchChange = () => {
    setShowAutolayoutModal({show: true});
  }

  const handelShowDropdownMenu = () => {
    isEdit && onEdit()
    setShowDropdownMenu(!showDropdownMenu);
  }
  const myCallback = (blob) => {
      var url = window.URL.createObjectURL(blob);
      var filename = "diagram.jpg";

      var a = document.createElement("a");
      a.style = "display: none";
      a.href = url;
      a.download = filename;

      // IE 11
      if (window.navigator.msSaveBlob !== undefined) {
        window.navigator.msSaveBlob(blob, filename);
        return;
      }

      document.body.appendChild(a);
      requestAnimationFrame(() => {
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      });
  }
  const makeBlob = () => {
    var d = diagram.documentBounds;
    var halfWidth = d.width * 0.7;
    var blob = diagram.makeImageData({ 
      background: "white", 
      returnType: "blob", 
      padding: 50,
      size: new go.Size(halfWidth, 500),
      callback: myCallback ,
    });
  }

  const print = () => {
    var d = diagram.documentBounds;
    var halfWidth = d.width / 2;
    var halfHeight = d.height / 2;

    var newWindow = window.open("","newWindow");
    if (!newWindow) return;
    var newDocument = newWindow.document;


    var svg = diagram.makeSvg({
      document: newDocument,  // create SVG DOM in new document context
      // scale: 1,
      size: new go.Size(NaN,250),
    });

    // Averta font -- begins
    const l = newDocument.createElement('link');
    const l2 = newDocument.createElement('link');

    l.setAttribute("href", "http://db.onlinewebfonts.com/c/150037e11f159dca84bc4c04549094b6?family=Averta-Regular");
    l.setAttribute("type", "text/css");
    l.setAttribute("rel", "stylesheet");
    l2.setAttribute("href", "http://db.onlinewebfonts.com/c/c36a75c5ada59665e225bbf5e38efd34?family=Averta-Bold");
    l2.setAttribute("type", "text/css");
    l2.setAttribute("rel", "stylesheet");

    newDocument.head.appendChild(l);
    newDocument.head.appendChild(l2);
    // Averta font -- ends

    newDocument.body.appendChild(svg);
  }


  const printCallback = (blob) => {
    var url = window.URL.createObjectURL(blob);
    const newWindow = window.open(url);
    newWindow.addEventListener('beforeunload', () => {
      URL.revokeObjectURL(url);
    });
  }

  const goJsFullscreen = () => {

    if(gojsWrapper.current) {

      goJsButtonsWrapperRef.current.style.pointerEvents = 'none';

      if(gojsWrapper.current.getBoundingClientRect().left !== 0) {

        gojsWrapper.current.style.position = "fixed";
        gojsWrapper.current.style.width = `${widthWrapper}px`;
        gojsWrapper.current.style.height = `${heightWrapper}px`;
        gojsWrapper.current.style.top = `${topWrapper}px`;
        gojsWrapper.current.style.left = `${leftWrapper}px`;
        gojsWrapper.current.style.zIndex = 99999;

        goJsButtonsWrapperRef.current.style.position = "fixed";
        goJsButtonsWrapperRef.current.style.width = `${widthButtons}px`;
        goJsButtonsWrapperRef.current.style.height = `${heightButtons}px`;
        goJsButtonsWrapperRef.current.style.top = `${topButtons}px`;
        goJsButtonsWrapperRef.current.style.left = `${leftButtons}px`;
        goJsButtonsWrapperRef.current.style.zIndex = 999999;

        gsap.to(goJsButtonsWrapperRef.current, {
          top: 24,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
          paddingRight: 16,
          duration: .7,
          ease: "cubic-bezier(.03,.5,.51,.91)",
          onComplete: (e) => {
            setTimeout(() => {
              goJsButtonsWrapperRef.current.style.pointerEvents = "initial";
            }, 500)
          }
        })

        gsap.to(gojsWrapper.current, {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
          duration: .7,
          ease: "cubic-bezier(.03,.5,.51,.91)",
        })
      }else {

        gsap.to(goJsButtonsWrapperRef.current, {
          top: topButtons,
          left: leftButtons,
          width: widthButtons,
          height: heightButtons,
          paddingRight: 0,
          duration: .7,
          ease: "cubic-bezier(.03,.5,.51,.91)",
          onComplete: (e) => {
            setTimeout(() => {
              goJsButtonsWrapperRef.current.style.position = "static";
              goJsButtonsWrapperRef.current.style.pointerEvents = "initial";
            }, 500)
          }
        })

        gsap.to(gojsWrapper.current, {
          top: topWrapper,
          left: leftWrapper,
          width: widthWrapper,
          height: heightWrapper,
          duration: .7,
          ease: "cubic-bezier(.03,.5,.51,.91)",
          onComplete: (e) => {
            setTimeout(() => {
              gojsWrapper.current.style.position = "static";
            }, 500)
          }
        })

      }
    }

  }

  useEffect(() => {
    setTimeout(() => {
      diagram.commandHandler.zoomToFit()
    }, 700)
  }, [])

  return (
    <React.Fragment>
      {showInfoModal && props.internalization.language === "en-US" && (
        <ModalInfo isShowed={true} backAction={() => setShowInfoModal(false)} />
      )}
      {showInfoModal && props.internalization.language === "es-ES" && (
        <ModalInfoEs isShowed={true} backAction={() => setShowInfoModal(false)} />
      )}

      {props.isHome !== true && (
        <div className="c-gojs__buttons-wrapper" ref={goJsButtonsWrapperRef}>
          {Object.keys(showEditModal).length > 0 && renderEditModal()}
          {Object.keys(showNewModal).length > 0 && renderNewModal()}
          {Object.keys(showDeleteModal).length > 0 && renderDeleteModal()}
          {Object.keys(showCommentModal).length > 0 && renderCommentModal()}
          {Object.keys(showUserModal).length > 0 && renderUserModal()}
          {Object.keys(showAutolayoutModal).length > 0 && renderAutoLayoutChangeModal()}
          <button
            type="button"
            title="Info"
            onClick={() => {
              setShowInfoModal(true);
            }}
            className="btn-circle-icon"
          >
            <Icon name="InfoIcon" />
          </button>

          <div className="c-gojs__buttons-wrapper">

            <button
              type="button"
              title="Fullscreen"
              onClick={() => goJsFullscreen() }
              className="btn-circle-icon"
              disabled={isEmpty}
            >
              <Icon name="Fullscreen" />
            </button>

            <button
              type="button"
              title="Zoom Fit"
              onClick={() => diagram.commandHandler.zoomToFit()}
              className="btn-circle-icon"
              disabled={isEmpty}
            >
              <Icon name="ZoomFit" />
            </button>

            <button
              type="button"
              title="Zoom In"
              onClick={() => {
                if (diagram.commandHandler.canIncreaseZoom()) diagram.commandHandler.increaseZoom();
              }}
              className="btn-circle-icon"
              disabled={isEmpty}
            >
              <Icon name="ZoomIn" />
            </button>

            <button
              type="button"
              title="Zoom Out"
              onClick={() => {
                if (diagram.commandHandler.canDecreaseZoom()) diagram.commandHandler.decreaseZoom();
              }}
              className="btn-circle-icon"
              disabled={isEmpty}
            >
              <Icon name="ZoomOut" />
            </button>

            <button
              type="button"
              title={props.internalization.buttons.actions}
              onClick={handelShowDropdownMenu}
              className="btn-circle-icon"
            >
              <Icon name={showDropdownMenu ? "Close" : "Menu"} color={"white"} width={18} />
            </button>



            <div className={showDropdownMenu ? "c-gojs__buttons-dropdown is-active" : "c-gojs__buttons-dropdown"} ref={buttonsDropdownRef}>

              <div className="c-gojs__layout-switch-wrapper">
                <div 
                  onClick={handleSwitchChange}
                  className={props.currentAutomaticLayout ? "c-gojs__layout-switch" : "c-gojs__layout-switch is-active"}
                >
                  <span className="c-gojs__layout-switch-text">Manual</span>
                  <span className="c-gojs__layout-switch-text">Auto</span>
                </div>
                <label className="c-gojs__layout-switch-label">{internalization[languageUserAgent].layout}</label>
              </div>

              <div style={{display: "flex", alignItems: "center", gap: 8}}>
                <button
                  type="button"
                  title="Show/hide figures"
                  onClick={() => {
                    deleteCookie("showAmount")
                    setCookie("showAmount", showAmount === "true" ? "false" : "true", 1)
                    setShowAmount(showAmount === "true" ? "false" : "true")
                    window.location.reload()
                  }}
                  className="btn-circle-icon"
                >
                  <Icon name="Dollar" bgColor="transparent" color="white" width={18} />
                </button>
                <span>{internalization[languageUserAgent].show_hide_figures}</span>
              </div>

              <div style={{display: "flex", alignItems: "center", gap: 8}}>
                <button
                  type="button"
                  title="Download"
                  onClick={() => {
                    makeBlob()
                  }}
                  className="btn-circle-icon"
                >
                  <Icon name="DocDownload" bgColor="transparent" color="white" width={18} />
                </button>
                <span>{internalization[languageUserAgent].download}</span>
              </div>

              <div style={{display: "flex", alignItems: "center", gap: 8}}>
                <button
                  type="button"
                  title="Print"
                  onClick={() => {
                    print()
                  }}
                  className="btn-circle-icon"
                >
                  <Icon name="Print" color="white" width={18} />
                </button>
                <span>{languageUserAgent === "es" ? "Imprimir" : "Print"}</span>
              </div>

            </div>

            <button
              type="button"
              title={isEdit ? "Close" : "Edit"}
              className={isEditButtonClasses()}
              style={{ backgroundColor: "#408197" }}
              onClick={() => props.can_manage !== false ? onEdit() : ""}
            >
              <Icon name={isEdit ? "Close" : "Edit"} color="white" width={"18px"} height={"18px"} />
            </button>
          </div>
        </div>
      )}

      <div className={props.isMobile ? "c-gojs is-mobile": "c-gojs"} ref={gojsWrapper}>
        {isEmpty && (
          <div
            style={{ cursor: props.isHome ? "pointer" : "default" }}
            onClick={props.isHome ? () => (window.location.href = "/wealth-map") : ""}
            className="c-gojs__diagram c-gojs__diagram-message"
          >
            <img src={general} className=" c-gojs__diagram-message-image" />
            <p className=" c-gojs__diagram-message-text headingH2 stackHeader-title">
              {props.internalization.no_wealthmap}
            </p>
          </div>
        )}

        {structure && (
          <ReactDiagram
            ref={diagramRef}
            initDiagram={initDiagram}
            divClassName={"c-gojs__diagram"}
            nodeDataArray={structure ? structure.nodeDataArray : []}
            linkDataArray={structure ? structure.linkDataArray : []}
            // nodeDataArray={nodeDataArray}
            // linkDataArray={linkDataArray}
            onDiagramEvent={handleOnDiagramEvent}
            onModelChange={handleModelChange}
          />
        )}

        {/* <div className='c-gojs__cover'></div> */}

        {props.isHome !== true && originDiagram && Object.keys(props.palettes).length > 0 && (
          <div className="c-gojs__palette-tabs" ref={tabsRef}>
            {props.palettes["head"]["elements"].length > 0 && (
              <div
                className="c-gojs__palette-tabs--item"
                data-tab-name={
                  props.palettes ? props.palettes["head"]["title"] : temp_paletes["head"]["title"]
                }
                onClick={() =>
                  handlePaletteTabs(
                    0,
                    props.palettes
                      ? props.palettes["head"]["elements"].length
                      : temp_paletes["head"]["elements"].length
                  )
                }
              >
                <ReactPalette
                  nodeDataArray={
                    props.palettes
                      ? props.palettes["head"]["elements"]
                      : temp_paletes["head"]["elements"]
                  }
                  initPalette={initPalette}
                  divClassName={"c-gojs__palette-tabs--item-canvas"}
                />
              </div>
            )}
            <div
              className="c-gojs__palette-tabs--item"
              data-tab-name={
                props.palettes
                  ? props.palettes["ownership"]["title"]
                  : temp_paletes["ownership"]["title"]
              }
              onClick={() =>
                handlePaletteTabs(
                  props.palettes["head"]["elements"].length == 0 ? 0 : 1,
                  props.palettes
                    ? props.palettes["ownership"]["elements"].length
                    : temp_paletes["ownership"]["elements"].length
                )
              }
            >
              <ReactPalette
                nodeDataArray={
                  props.palettes
                    ? props.palettes["ownership"]["elements"]
                    : temp_paletes["ownership"]["elements"]
                }
                initPalette={initPaletteTwo}
                divClassName={"c-gojs__palette-tabs--item-canvas"}
              />
            </div>
            <div
              className="c-gojs__palette-tabs--item"
              data-tab-name={
                props.palettes ? props.palettes["assets"]["title"] : temp_paletes["assets"]["title"]
              }
              onClick={() =>
                handlePaletteTabs(
                  props.palettes["head"]["elements"].length == 0 ? 1 : 2,
                  props.palettes
                    ? props.palettes["assets"]["elements"].length
                    : temp_paletes["assets"]["elements"].length
                )
              }
            >
              <ReactPalette
                nodeDataArray={
                  props.palettes
                    ? props.palettes["assets"]["elements"]
                    : temp_paletes["assets"]["elements"]
                }
                initPalette={initPaletteThree}
                divClassName={"c-gojs__palette-tabs--item-canvas"}
              />
            </div>
            <div
              className="c-gojs__palette-tabs--item"
              data-tab-name={
                props.palettes
                  ? props.palettes["liabilities"]["title"]
                  : temp_paletes["liabilities"]["title"]
              }
              onClick={() =>
                handlePaletteTabs(
                  props.palettes["head"]["elements"].length == 0 ? 2 : 3,
                  props.palettes
                    ? props.palettes["liabilities"]["elements"].length
                    : temp_paletes["liabilities"]["elements"].length
                )
              }
            >
              <ReactPalette
                nodeDataArray={
                  props.palettes
                    ? props.palettes["liabilities"]["elements"]
                    : temp_paletes["liabilities"]["elements"]
                }
                initPalette={initPaletteThree}
                divClassName={"c-gojs__palette-tabs--item-canvas"}
              />
            </div>
            <div
              className="c-gojs__palette-tabs--item"
              data-tab-name={
                props.palettes ? props.palettes["tools"]["title"] : temp_paletes["tools"]["title"]
              }
              onClick={() =>
                handlePaletteTabs(
                  props.palettes["head"]["elements"].length == 0 ? 3 : 4,
                  props.palettes
                    ? props.palettes["tools"]["elements"].length
                    : temp_paletes["tools"]["elements"].length
                )
              }
            >
              <ReactPalette
                nodeDataArray={
                  props.palettes
                    ? props.palettes["tools"]["elements"]
                    : temp_paletes["tools"]["elements"]
                }
                initPalette={initPalette}
                divClassName={"c-gojs__palette-tabs--item-canvas"}
              />
            </div>

            {!isEmpty && props.internalization && props.internalization.buttons && (
              <div style={{ margin: 10 }}>
                <p style={{ textAlign: "center", color: colors.blueDark }}>
                  {props.internalization.buttons.automatic_save}
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    permits: state.session.permits      
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GojsCanvas);
