import React, { forwardRef, memo, useRef, useState } from 'react'
import classNames from "classnames";
import Icon from "../../components/icons";

const DashboardTableHeaderRow = forwardRef((props, ref) => {
  const { item, index, headers, tooltips } = props

  const tooltipRef = useRef(null)

  const handleHeaderCellClassNames = (index) => {
    return classNames(
      "c-table-dashboard__cell",
      index === 1 && "is-first"
    )
  }

  return (
    <div 
      key={`${index}-head-item`}
      ref={ref}
      className={handleHeaderCellClassNames(index)}
    >

      {tooltips[item] && (
        <div 
          ref={tooltipRef}
          className="c-table-dashboard__cell-tooltip" 
          onClick={(e) => {
            e.target.classList.toggle('opened')
          }} 
          data-item={item}
        > 
          <span>
            <Icon name="InfoTooltip" noWrapper={true} /> 
          </span>
          <div
            className="container-tooltip container-tooltip--no-triangle c-table-dashboard__cell-tooltip-body"
            onClick={() => { 
              tooltipRef.current.classList.remove('opened')
            }}
          >
            <p style={{ textAlign: "center" }} dangerouslySetInnerHTML={{ __html: tooltips[item] }}></p>
          </div>
        </div> 
      )}

      <span>{headers[item]}</span>

    </div>
  )
})  

export default memo(DashboardTableHeaderRow)
