// Dependencies
import React from "react";

const LogoPro = ({ width = 136, height = 103 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 136 103">
      <path
        d="M0 8.41998H5.32V28.61C6.45765 26.7501 8.06019 25.2187 9.96975 24.1666C11.8793 23.1145 14.03 22.578 16.21 22.61C24.47 22.61 29.12 28.12 29.12 37.41C29.12 50.01 20.43 54.11 12.05 54.11C7.94593 54.0599 3.87688 53.3474 0 52L0 8.41998ZM12.54 49.1C18.54 49.1 23.61 45.98 23.61 37.78C23.61 31.11 20.37 27.69 14.61 27.69C13.072 27.7625 11.5716 28.1892 10.2256 28.9368C8.87957 29.6844 7.72437 30.7327 6.85 32C5.83519 33.6746 5.34417 35.6143 5.44 37.57V47.81C7.69159 48.7277 10.1095 49.167 12.54 49.1Z"
        fill="#005874"
      />
      <path
        d="M43.84 48.06C43.6469 47.1974 43.5663 46.3134 43.6 45.43V27.81H51.45L50.35 23.35H43.6V12.15L38.28 14.32V45.61C38.1654 47.4365 38.41 49.2677 39 51C40 53.33 42 54.12 44.75 54.12C46.1228 54.1269 47.4829 53.8583 48.75 53.33V48.43C47.8849 48.828 46.9512 49.0555 46 49.1C44.7 49.1 44.15 48.73 43.84 48.06Z"
        fill="#005874"
      />
      <path
        d="M47.82 18C47.82 18 44.93 8.45997 58.07 8.59997C66.01 8.74997 71.49 14.24 86.22 12.22C100.95 10.2 107.29 3.21997 107.29 3.21997C107.29 3.21997 93.29 17.96 72.79 17.22C66.55 17.05 55.9 14.23 47.82 18Z"
        fill="#F4B200"
      />
      <path
        d="M70.31 9.59C72.1377 10.0529 73.9956 10.387 75.87 10.59C80.8358 8.18306 85.0965 4.53548 88.24 0C82 7.47 70.42 9.57 70.31 9.59Z"
        fill="#F4B200"
      />
      <path
        d="M84.78 48.35C83.1068 45.2695 82.3015 41.7925 82.45 38.29C82.45 30.29 86.84 21.14 96.68 21.14C99.7031 21.1001 102.703 21.6716 105.5 22.82V27.42C102.98 26.2189 100.231 25.5743 97.44 25.53C89.92 25.53 87.97 31.53 87.44 35.64C87.3494 36.5738 87.3127 37.512 87.33 38.45C88.74 35.37 92.96 33.45 96.8 33.45C102.53 33.45 108.1 36.64 108.1 43.45C108.1 50.26 102.26 54.21 95.72 54.21C90.51 54.19 86.84 51.81 84.78 48.35ZM103.22 43.27C103.22 39.75 100.74 37.1 95.81 37.1C90.88 37.1 88 40.34 88 43.27C88 47.27 91.94 49.7 95.41 49.7C100.47 49.7 103.22 46.89 103.22 43.27Z"
        fill="#005874"
      />
      <path
        d="M122.6 54.24C114.6 54.24 110.21 47.05 110.21 37.64C110.21 28.23 115.51 21.09 122.82 21.09C130.98 21.09 135.31 28.18 135.31 37.64C135.31 47.1 130.71 54.24 122.6 54.24ZM122.82 25.58C117.14 25.58 115.03 31.64 115.03 37.69C115.03 43.74 116.97 49.81 122.76 49.81C128.55 49.81 130.44 43.7 130.44 37.69C130.44 31.47 128.66 25.58 122.82 25.58Z"
        fill="#005874"
      />
      <path
        d="M58.6 102.168V65.3495H62.832V69.5815C63.8356 68.1432 65.1713 66.9682 66.7258 66.1563C68.2804 65.3444 70.0079 64.9196 71.7617 64.9178C78.3298 64.9178 82.4433 69.0313 82.4433 77.0045C82.4433 86.9837 75.5705 90.7502 68.9008 90.7502C66.9177 90.7168 64.9468 90.4324 63.0352 89.9038V102.168H58.6ZM68.7569 86.7213C73.6153 86.7213 77.9827 84.182 77.9827 77.4108C77.9827 71.4859 74.9695 69.0398 70.509 69.0398C69.2952 69.0554 68.1011 69.3488 67.0183 69.8975C65.9355 70.4463 64.9928 71.2357 64.2625 72.2054C63.4625 73.4458 63.0446 74.8938 63.0606 76.3697V85.6802C64.8553 86.4267 66.7887 86.7816 68.7315 86.7213H68.7569Z"
        fill="#F4B200"
      />
      <path
        d="M89.5616 90.2847V65.3495H93.9291V70.3687C94.5491 68.7617 95.6445 67.3822 97.0692 66.4143C98.4939 65.4463 100.18 64.9361 101.902 64.9517C103.757 64.8809 105.585 65.4092 107.116 66.4583L104.856 70.5126C103.669 69.5884 102.205 69.0932 100.7 69.1075C97.3824 69.1075 94.8771 72.5693 94.4285 74.6769C94.1218 76.4827 93.9858 78.3134 94.0222 80.1447V90.3016L89.5616 90.2847Z"
        fill="#F4B200"
      />
      <path
        d="M122.174 90.8264C114.344 90.8264 109.181 85.604 109.181 77.8848C109.181 70.5126 114.26 64.7401 122.275 64.7401C130.291 64.7401 135.31 70.2078 135.31 77.7324C135.31 85.257 129.961 90.8264 122.174 90.8264ZM122.275 68.8197C116.257 68.8197 113.642 73.2888 113.642 77.8509C113.642 82.7178 116.604 86.679 122.275 86.679C127.946 86.679 130.816 82.3623 130.816 77.7917C130.816 73.2211 128.31 68.8621 122.292 68.8621L122.275 68.8197Z"
        fill="#F4B200"
      />
      <path
        d="M73.85 36.61L73.64 36.5C75.3066 36.2202 76.8176 35.3518 77.8984 34.0527C78.9792 32.7535 79.5581 31.1097 79.53 29.42C79.53 23.04 73.42 21.14 69.04 21.14C66.1195 21.1524 63.2305 21.7438 60.54 22.88L59.26 28C60.5 27.4 64.56 25.57 68.07 25.57C70.77 25.57 74.34 26.7 74.34 30.57C74.34 33.27 71.69 35.38 66.66 35.38V38.68C70.93 38.68 74.94 39.92 74.94 43.92C74.94 48.25 71.47 49.77 68.45 49.77C64.66 49.77 60.6 48.14 58.6 47.28V51.87C61.9945 53.4765 65.7153 54.2741 69.47 54.2C72.99 54.2 80.34 52.25 80.34 44.63C80.4011 42.7368 79.779 40.8848 78.5875 39.4123C77.3959 37.9398 75.7143 36.9451 73.85 36.61Z"
        fill="#005874"
      />
    </svg>
  );
};

export default LogoPro;
