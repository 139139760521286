// Dependencies
import React from "react";

const Pro = ({ width = 91, height = 45, color = '#005874' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 91 45">
      <path
        d="M0.181641 44.4514V0.951406H5.18164V5.95141C6.36727 4.2521 7.94538 2.86393 9.78201 1.9047C11.6186 0.945468 13.6596 0.443502 15.7316 0.441406C23.4916 0.441406 28.3516 5.3014 28.3516 14.7214C28.3516 26.5114 20.2316 30.9614 12.3516 30.9614C10.0087 30.922 7.68016 30.586 5.42165 29.9614V44.4514H0.181641ZM12.1816 26.2014C17.9216 26.2014 23.0816 23.2014 23.0816 15.2014C23.0816 8.20141 19.5216 5.31141 14.2516 5.31141C12.8176 5.32982 11.4068 5.67648 10.1275 6.32478C8.84826 6.97309 7.73447 7.90582 6.87164 9.05141C5.92654 10.5169 5.43276 12.2277 5.45164 13.9714V24.9714C7.57204 25.8534 9.85624 26.2727 12.1516 26.2014H12.1816Z"
        fill={color}
      />
      <path
        d="M36.7617 30.412V0.952026H41.9217V6.88203C42.6542 4.98351 43.9485 3.35364 45.6317 2.21007C47.3149 1.06649 49.3069 0.463647 51.3417 0.482027C53.5329 0.398476 55.6928 1.0226 57.5017 2.26203L54.8317 7.05203C53.4294 5.96017 51.6989 5.37509 49.9217 5.39203C46.0017 5.39203 43.0417 9.48202 42.5117 11.972C42.1494 14.1055 41.9887 16.2684 42.0317 18.432V30.432L36.7617 30.412Z"
        fill={color}
      />
      <path
        d="M75.2914 31.0514C66.0414 31.0514 59.9414 24.8814 59.9414 15.7614C59.9414 7.05144 65.9414 0.231445 75.4114 0.231445C84.8814 0.231445 90.8114 6.69144 90.8114 15.5814C90.8114 24.4714 84.4914 31.0514 75.2914 31.0514ZM75.4114 5.05145C68.3014 5.05145 65.2114 10.3314 65.2114 15.7214C65.2114 21.4714 68.7114 26.1514 75.4114 26.1514C82.1114 26.1514 85.5014 21.0514 85.5014 15.6514C85.5014 10.2514 82.5414 5.10144 75.4314 5.10144L75.4114 5.05145Z"
        fill={color}
      />
    </svg>
  );
};

export default Pro;
