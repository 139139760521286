import React, { forwardRef, useRef, useImperativeHandle, memo, useEffect } from "react";
import Icon from "../../components/icons";

const DashboardTableBodyRow = forwardRef((props, ref) => {
  const {
    item,
    indexRow,
    showCloseContent,
    setSelectedRowIndex,
    updateNetWorthData,
    currentUser,
    handleChangeUser,
    handleFavorite,
    getContentData,
  } = props;

  const rowRef = useRef(null);
  const chevronRef = useRef(null);
  const starRef = useRef(null);

  useImperativeHandle(ref, () => {
    return {
      row: rowRef.current,
      chevron: chevronRef.current,
      star: starRef.current,
    };
  });

  return (
    <div
      key={`${indexRow}-row`}
      className="c-table-dashboard__row"
      ref={rowRef}
    >
      {Object.entries(item).map(([key, value], index) => {
        // If it's not content we display a table cell
        if (key !== "content" && key !== "id" && index !== 0) {
          if (index === 1) {
            return (
              <div
                key={`${index}-body-item`}
                className="c-table-dashboard__cell is-first"
              >
                <div className="c-table-dashboard__cell-first-content">
                  <div
                    className="c-table-dashboard__cell-chevron"
                    ref={chevronRef}
                    onClick={() => {
                      showCloseContent(indexRow);
                      setSelectedRowIndex(indexRow);
                      updateNetWorthData(item.id, indexRow);
                      getContentData(item.id)
                    }}
                  >
                    <Icon name="SingleArrow" orientation={"down"} />
                  </div>
                  <span
                    style={{ cursor: "pointer" }}
                    data-user
                    // onClick={(e) => item.id == currentUser.id ? window.location.href = '/' : handleChangeUser(e, item) }
                    onClick={(e) => handleChangeUser(e, item) }
                  >
                    {/* {indexRow + 1} */}
                    {value}
                  </span>
                  <span
                    ref={starRef}
                    className="c-table-dashboard__cell-star"
                    style={{ opacity: item.is_favorite ? 1 : 0.3 }}
                    onClick={() => handleFavorite(item, indexRow)}
                  >
                    <Icon name="Star" color="#f3b01c" />
                  </span>
                </div>
              </div>
            );
          } else if (value.url || value.url === "") {
            return (
              <div
                className="c-table-dashboard__cell"
                key={`${index}-body-item`}
              >
                {value.url !== "" && (
                  <a href={value.url} target="_blank">
                    <Icon name="Eye" />
                  </a>
                )}
              </div>
            );
          } else if (value === true) {
            return (
              <div
                className="c-table-dashboard__cell"
                key={`${index}-body-item`}
              >
                <a href={value.url} target="_blank" rel="noreferrer">
                  <Icon name="Check" />
                </a>
              </div>
            );
          } else if (value === false) {
            return (
              <div
                className="c-table-dashboard__cell"
                key={`${index}-body-item`}
              >
                <a href={value.url} target="_blank" rel="noreferrer">
                  <Icon name="Close" color="#f3b01c" />
                </a>
              </div>
            );
          } else if (value.value) {
            return (
              <div
                className="c-table-dashboard__cell"
                key={`${index}-body-item`}
              >
                <span style={{ flex: 1 }}>{value.value}</span>
              </div>
            );
          } else {
            return (
              <div
                className="c-table-dashboard__cell"
                key={`${index}-body-item`}
              >
                <span>{value}</span>
              </div>
            );
          }
        }
      })}
    </div>
  );
});

export default memo(DashboardTableBodyRow)
