// Dependencies
import React from "react";
import { connect } from "react-redux";
import 'react-phone-number-input/style.css'
import PhoneInput, {getCountryCallingCode, parsePhoneNumber} from 'react-phone-number-input'

// Constants
import {internalization} from '../../constants.js'

// Actions
import { updateUser, getUser, createUserInvitation, getProfileCM } from "../../actions/session";
import { getInvitation, updateInvitation, deleteInvitation, resendInvitation } from "../../actions/permission";

//Components
import Tabs from "../../components/Tabs";
import Table from "../../components/Table";
import Input from "../../components/input";
import TableBlock from "../../components/tableBlock";
import AssetsList from "../../components/assetsList";
import Invitations from "../../components/invitations";
import CustomAvatar from "../../components/customAvatar";
import Modal from "../../components/modal";
import AlertModal from "../../components/alertModal";
import ProfilePermission from "../../components/profilePermission";
import DetailContentBlock from "../../components/detailContentBlock";
import MediaBlock from "../../components/mediaBlock";
import RenderDocIcon from "../../helpers/renderDocIcon";
import LinkBlock from "../../components/linkBlock";
import SelectMultiple from "../../components/selectMultiple";
import ModalDeleteUser from "../../components/modalDeleteUser";
import Icon from "../../components/icons";
import Loading from "../../components/loading";

//Helpers
import { avatars } from "../../assets/resources";

class ProfileEditScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      refreshing: false,
      showModal: true,
      avatar_selected: {},
      loadingImages: false,
      updateUser: {},
      user_attributes: {},
      avatar_changed: false,
      options_language: [],
      screen_step: "profile",
      invitation_attributes: {},
      classOpen: "",
      checkboxes: [],
      permissions_selected: {manage: {}, read: {}},
      new_invitation_values: {manage: {}, read: {}},
      idInvitation: "",
      showInvitationModal: false,
      form_errors:{},
      errors: {},
      show_input_ofuscated: {password: true, phone_number: true, email:true, pin:true},
      reset:false,
      showPinView:false,
      showPinViewAttribute: "",
      editing: false,
      showSuccessModal: false,
      selectItem: "",
      showDeleteUserModal:false,
      showDeleteUserModalData: {},
      accessCheck: props.user.acceptance_bulltick_access,
      editPrivacy: false,
      accessPrivacy: {},
      invitationCont: 0,
      showActionModal: false,
      modal_title: "",
      modal_body: "",
      modal_action: "",
      deleteGuestModalData: {},
      showSuccesUpdateDataModal: false,
      showProfilePermissionAlert: false,
      screen_step_index: 0,
      confirmChanges: false,
      processing: false
    };

    this.calendar = null;
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }



  _success() {
    this.setState({
      refreshing: false,
      showSuccesModal: true,
      modal_title: this.props.internalization.modals.profile.updated.title,
      modal_body: this.props.internalization.modals.profile.updated.body ,
      accessPrivacy: {},
      confirmChanges: false,
      editPrivacy:false,
      processing: false
    });

    this._fetch()
  }

  _error() {
    alert("error");
  }

  _getInputValues(state, e) {
    if (this.state.screen_step === "profile") {
      this.setState({
        user_attributes: {
          ...state.user_attributes,
          [e.target.name]: e.target.value
        }
      });
    } else if (this.state.screen_step === "access") {
      this.setState({
        invitation_attributes: {
          ...state.invitation_attributes,
          [e.target.name]: e.target.value
        }
      });
    }

    this.props.history.replace({pathname: this.props.location.pathname, state: {}})
  }

  _getPickerValues(state, e) {

    let idx = e.target.selectedIndex;

    this.setState({
      user_attributes: {
        ...state.user_attributes,
        [e.target.name]: e.target.options[idx].label
      },
      selected_option: idx
    });
  }

  _setFileSelected = (value, name) => {
    this.setState({
      avatar_selected: value,
      loadingImages: false,
      avatar_changed: true
    });
  };

  _clickToLoadFormElement(e) {
    e.preventDefault();
    this.inputElementFile.click();
  }

  _loadFormElement(e, state) {
    const fr = new FileReader();
    let fileIntermediate;
    if (e.target.files !== undefined) {
      fileIntermediate = {
        filename: e.target.files.name,
        content_type: e.target.files.type
      };
    }
    fr.addEventListener("load", () => {
      fileIntermediate.data = fr.result.split("base64,")[1];

      this._setFileSelected(fileIntermediate, "images");
    });

    fr.readAsDataURL(e.target.files[0]);
  }

  _save(state) {
    let hash_prepared;
    if (state.avatar_changed) {
      hash_prepared =
        state.avatar_selected.id !== undefined
        ? {
          user: {
            avatar: { destroy: true },
            avatar_default_id: state.avatar_selected.id
          }
        }
        : { user: { avatar: state.avatar_selected } };
    } else {
      hash_prepared = { user: {} };
    }
    if (Object.keys(state.user_attributes).length > 0)
      Object.assign(hash_prepared["user"], state.user_attributes);

    this.props.updateUser(
      this.props.authentication_token,
      hash_prepared,
      () => this._success(),
      (responseJSON) => alert("Error")
    );
  }

  _successInvitation(responseJSON){
    this.setState({
      invitation_attributes: {},
      user_attributes: {},
      checkboxes: [],
      permissions_selected: {manage: {}, read: {}},
      classOpen: "",
      showSuccesModal: true,
      modal_title: this.props.internalization.modals.invitation.created.title,
      modal_body: this.props.internalization.modals.invitation.created.body,
      invitationCont: this.state.invitationCont + 1,

    })
    this.props.getUser(this.props.authentication_token, (responseJSON) => this._getUserSuccess(responseJSON), () => alert("ko"))
  }

  _errorInvitation(){
    this.setState({
      invitation_attributes: {},
      user_attributes: {},
      checkboxes: [],
      permissions_selected: {manage: {}, read: {}},
      classOpen: "",
      showSuccesModal: true,
      modal_title: "Error",
      modal_body: this.props.internalization.alerts.email_used 

    })
  }

  _sendInvitation(state) {

    let user = state.invitation_attributes
    let final_result_read = Object.fromEntries(
      Object.entries(state.new_invitation_values["read"]).filter(([key, value]) => typeof value !== 'object')
    );
    let final_result_manage = Object.fromEntries(
      Object.entries(state.new_invitation_values["manage"]).filter(([key, value]) => typeof value !== 'object')
    );
    user["permits"] = {
      "read": final_result_read, 
      "manage": final_result_manage
    }

    this.props.createUserInvitation(user, this.props.authentication_token, (responseJSON) => this._successInvitation(responseJSON), () => this._errorInvitation())
  }

  _getUserSuccess() {
    this.checkPro()
    if(this.props.profile.hasOwnProperty('access') && Object.keys(this.props.profile.access).length > 0 ){
      let read_checkboxes = this.props.profile.access.form.add_invitation.inputs.filter((e) => e.id.indexOf('permits[read]') !== -1)      
      let manage_checkboxes = this.props.profile.access.form.add_invitation.inputs.filter((e) => e.id.indexOf('permits[manage]') !== -1)
          
      // let read_checkboxes = {
      //         "bank_account":true,"account_receivable":true,"real_estate":true,"direct_investment":true,"vehicle":true,"watch":true,"art":true,"life_insurance":true,"other":true,"liabilities":true,"financial_assets":true,"ownership":true,"wealth_map":true
      //       }
      // let manage_checkboxes = {
      //         "bank_account":true,"account_receivable":true,"real_estate":true,"direct_investment":true,"vehicle":true,"watch":true,"art":true,"life_insurance":true,"other":true,"liabilities":true,"financial_assets":true,"ownership":true,"wealth_map":true
      //       }  
      this.setState({checkboxes: {manage: manage_checkboxes, read:read_checkboxes}, permissions_selected: {manage: manage_checkboxes, read:read_checkboxes}})
    }

    let code_selected = this._getUrlVars()["code"]
    if(code_selected !== undefined){
      let tab_selected = this.props.profile.tabs.filter(button => button.code === code_selected)
      let tab_index = this.props.profile.tabs.findIndex((tab) => tab.code === code_selected)
      if(tab_selected.length > 0) this._tabPressed(tab_selected[0], tab_index)
    }
    else{
      this.props.getBreadcrumbs({
        url:
        this.props.history.location.pathname +
        this.props.history.location.search,
        assetUrl: this.props.history.location.pathname
      });
    }   
    this.setState({loading: false})
    this.props.getProfileCM(this.props.authentication_token, (responseJSON) => console.log(responseJSON), () => console.log("error cm retrive") )
  }

  componentWillUnmount() {

    this.setState({ showModal: true });
  }

  componentDidMount() {
    
    this.setState({
      screen_step: "profile"
    })
    this.props.getUser(
      this.props.authentication_token,
      () => this._getUserSuccess(),
      () => alert("Error")
    );

    let avatar_user = this.props.user.avatar_user;
    if (avatar_user.avatar_default_id != null) {
      this.setState({
        avatar_selected: {
          id: avatar_user.avatar_default_id,
          image: avatars[avatar_user.avatar_default_id].default
        }
      });
    } else if (avatar_user.url != null) {
      this.setState({ avatar_selected: { url: avatar_user.url } });
    } 

    this.props.getBreadcrumbs({
      url:
      this.props.history.location.pathname +
      this.props.history.location.search,
      assetUrl: this.props.history.location.pathname
    });

    document.addEventListener("click", this.handleClickOutside);
  }

  checkPro() {
    if(window.location.pathname === "/pro/profile"){
      let tabs = document.querySelectorAll(".c-tabs__container .c-tabs")
      if(tabs[1]) tabs[1].style.display = "none"
      if(tabs[2]) tabs[2].style.display = "none"
      if(tabs[3]) tabs[3].style.display = "none"

      const menu = document.querySelector(".c-header__content-bottom")
      menu.style.display = "none";
      const buttons = document.querySelectorAll(".sidebar-items > button")
      buttons.forEach((button,i) => {
        if (i > 0){
          button.remove()
        }
      })
      const separators = document.querySelectorAll(".sidebar-items > div")
      separators.forEach((separator,i) => {
        separator.remove()
      })

      // document.querySelector(".burger-menu").style.display = "none"
    }
  }

  handleClickOutside(e) {

    let allMultiSelects = document.querySelectorAll('.options-multiple-select');
    let containerMultiSelects = document.querySelectorAll('.container-multiple-select')
    let multiSelectIcon = document.querySelectorAll('.icons-multiple-select')

    for (let i = 0; i < allMultiSelects.length; i++) {
      if (allMultiSelects.length > 0 && multiSelectIcon.length > 0 && containerMultiSelects.length > 0 && containerMultiSelects && !containerMultiSelects[i].contains(e.target)) {

        containerMultiSelects[i].classList.remove('open')
        multiSelectIcon[i] && multiSelectIcon[i].classList.remove('rotate')
        allMultiSelects[i].style.height = 0

        setTimeout(function () { containerMultiSelects[i].style.zIndex = 0 }, 500);

        allMultiSelects[i].classList.remove('open')

      }
    }

  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.location.state && Object.keys(this.props.location.state).length > 0 && this.props.location.state.from_header && this.props.location.pathname === prevProps.location.pathname && prevState.screen_step !== "index" ) {
      // this.onRouteChanged();
    }
  }

  onRouteChanged() {
    this.setState({
      screen_step: "profile",
      classOpen: ""
    })
    this.props.history.replace({pathname: this.props.location.pathname, state: {}})
  }

  _fetch(){
    this.props.getUser(
      this.props.authentication_token,
      () => this._getUserSuccess(),
      () => alert("Error")
    );

  }

  /*
   * componentDidUpdate(prevProps) {
   *     this.props.getBreadcrumbs({
   *         url:
   *         this.props.history.location.pathname +
   *         this.props.history.location.search,
   *         assetUrl: this.props.history.location.pathname
   *     });
   * }
   */
  _getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
      vars[key] = value;
    });
    return vars;
  }   



  _openInvitation(state){

    this.setState({
      classOpen: state.classOpen === "" ? "open" : "",
    })

    this.props.history.replace({pathname: this.props.location.pathname, state: {}})
  }

  _newImage(value, imgDefault){
    if(imgDefault){
      this.setState({
        avatar_changed: true,
        avatar_selected: {
          id: value.id,
          image: value.image
        }
      })
    }else{
      this.setState({
        avatar_selected: value,
        loadingImages: false,
        avatar_changed: true
      })
    }

  }

  _closeModal(){

    this.setState({ showInvitationModal: false })

    this._fetch()

  }


  _ofuscated(type, value){
    switch (type) {
      case 'password':{
        return "********"
        break;
      }
      case 'pin':{
        return value.length > 0 ? value[0]+"****"+value[1] : ""
        break;
      }
      case 'email':{
        return this._protect_email(value)
        break;
      }
      case 'phone_number':{
        return this._protect_phone(value)
        break;
      }
      default: {console.log("error")}
    }


  }

  _disableOfuscated(e,id,state,){
    e.stopPropagation()
    e.preventDefault()

    this.setState({show_input_ofuscated: { ...this.state.show_input_ofuscated, [id]: !this.state.show_input_ofuscated[id] } });  
    this.props.history.replace({pathname: this.props.location.pathname, state: {}})
  }
  _protect_phone(phone) {
    var result = "********";
    try{
      let to_divide = result.length/2/2
      result = phone.substring(0,to_divide) + "****"+phone.substring(phone.length - to_divide, phone.length)
    }

    catch(e){

    }
    return result;
  }
  _protect_email(user_email) {
    var avg, splitted, part1, part2;
    splitted = user_email.split("@");
    part1 = splitted[0];
    avg = part1.length / 2;
    part1 = part1.substring(0, (part1.length - avg));
    part2 = splitted[1];
    return part1 + "...@" + part2;
  };


  _getTitleChange(id){
    switch (id) {
      case 'password':{
        return "Password"
        break;
      }
      case 'pin':{
        return "Pin"
        break;
      }
      case 'email':{
        return "Email"
        break;
      }
      case 'phone_number':{
        return "Phone Number"
        break;
      }
      default: {console.log("error")}
    }
  }   

  goToEdit(item){
    this.props.history.push('/profile_edit', {title: item.label, type: item.id})

    // this.props.history.replace({pathname: this.props.location.pathname, state: {}})
  }


  _tabPressed(button, index){
    if(!window.location.pathname.includes("/pro/profile")){
       this.props.getBreadcrumbs({
        url: '/profile_'+button.code,
        assetUrl: '/profile_'+button.code,
        assetTitle: button.title
      });
    }
   
    window.history.pushState("", "", "profile?code="+button.code);


    this.setState({ editing: false, screen_step: button.code, screen_step_index: index })

  }

  _getTabIndexFromCode(code) {
    return this.props.profile.tabs.findIndex((tab) => tab.code === code)
  }

  _goFromInfo(element){

    if(element.code === "last_activity"){
      this.props.history.push("/notifications")
    }
    else if (element.code === "authorized_bulltick_persons"){
      this.setState({screen_step: "privacy", screen_step_index: this._getTabIndexFromCode("privacy")})
      window.history.pushState("", "", "profile?code=privacy");

    }else if(element.code === "authorized_persons"){
      this.setState({screen_step: "access", screen_step_index: this._getTabIndexFromCode("access")})
       window.history.pushState("", "", "profile?code=access");
    }


  }

  _getOptions(items, state, key){

    this.setState({
      [key]: {
        ...state[key],
        ["language"]: items.item.id
      },
    });
  }

  _openDeleteUser(state){
    alert("delete")
  }

  _requirementMatchElement(icon, color, text) {
    return (
      <div className="requirements-access">
        <Icon name={icon} color={color} width="12px" height="12px" className="wrapper-requirements-options__list-icon"/>
        <p className="text">{text}</p>
      </div>  
    )
  }

  _checkPhoneConfirmation(languageUserAgent) {

    if (this.state.invitation_attributes 
      && this.state.invitation_attributes.phone_number 
      && this.state.invitation_attributes.phone_confirmation) {
      if (this.state.invitation_attributes.phone_number === this.state.invitation_attributes.phone_confirmation) {
        return (
          this._requirementMatchElement("Check", "#408197", internalization[languageUserAgent].errors.match)
        )
      }else {
        return (
          this._requirementMatchElement("Close", "#f3b01c", internalization[languageUserAgent].errors.no_match)
        )    
      }
    }
  }

  _checkPermissions(){
    return Object.values(this.state.new_invitation_values.manage).every((value) => value === 0) && Object.values(this.state.new_invitation_values.read).every((value) => value === 0)
  }

 _checkSelected() {
  return [
    "liabilities",
    "ownership",
    "wealth_map",
    "bank_account",
    "account_receivable",
    "real_estate",
    "direct_investment",
    "vehicle",
    "watch",
    "art",
    "other",
    "life_insurance",
    "financial_assets"
    ]
  }

  _someValueOne(hash, keys){
    for (const key of keys) {
      if ((key in hash) && hash[key] == 1) {
        return true;
      }
    }
    return false;
  }

  _checkInputInvitations(languageUserAgent){
    return (
      this.state.invitation_attributes.hasOwnProperty("language") && 
      this.state.invitation_attributes.hasOwnProperty("name") && 
      this.state.invitation_attributes.hasOwnProperty("email") && 
      this.state.invitation_attributes.hasOwnProperty("phone_number") && 
      this._checkPhoneConfirmation(languageUserAgent) && 
      !this._checkPermissions() &&
      this._someValueOne(this.state.new_invitation_values["read"], this._checkSelected())
      )
  }

  _saveAccess(accepted = false){
    if (Object.keys(this.state.accessPrivacy).length > 0 && accepted){
      let data = { user: {acceptance_bulltick_access: this.state.accessPrivacy.acceptance_bulltick_access}}
      this.setState({processing: true})
      this.props.updateUser(
        this.props.authentication_token,
        data,
        () => this._success(),
        (responseJSON) => alert("Error")
      );  
    }else{
      this.setState({confirmChanges: true})
    }
    

  }

  _getLanguageLegible(language){

    switch(language){
      case 'es-ES': return "Español"
      case 'en-US': return "English"
      default: return "English"
    }
  } 

  _successResdendInvitation(){
    this.setState({
      showActionModal: false,
      showSuccesModal: true, 
      modal_title: "Resend invitation", 
      modal_body: "The invitation has been sent successfully.",
    })
  }

  _confirmDelete(){
    this.props.deleteInvitation( this.state.idInvitation, this.props.authentication_token, () => {
      this.setState({
        modal_action: () => this._closeModal()}
      )
    }, () => alert("error")
    )
  }

  render() {

    var languageUserAgent = (/^es/).test(this.props.user.language) ? 'es' : 'en';

    let current_image;
    if (Object.keys(this.state.avatar_selected).length > 0) {
      if (this.state.avatar_selected.id !== undefined) {
        current_image = this.state.avatar_selected.image;
      } else if (this.state.avatar_selected.url !== undefined) {
        current_image = this.state.avatar_selected.url;
      } else {
        current_image =
          `data:${this.state.avatar_selected.content_type};base64,` +
          this.state.avatar_selected.data;
      }
    }

    let inputs = []
    if(this.props.profile && Object.keys(this.props.profile).length > 0){
      inputs = this.props.profile.profile.form.edit_profile.inputs.filter((e) => !["avatar","id_bulltick"].includes(e.id))

    }
    if(this.props.assigned_users.length > 0){
      inputs = inputs.filter((e) => !["field_to_show_ownership"].includes(e.id))       
    }

    return (
      <>
        {this.state.loading && <Loading text={this.props.internalization.loading}  show={this.state.loading} />}

      <div className="main-wrapper">
        {/* ProfilePermission modals */}
        {
          this.state.showInvitationModal && 
          Object.entries(this.state.deleteGuestModalData).length === 0 &&
          !this.state.showSuccesUpdateDataModal &&
          !this.state.showProfilePermissionAlert &&
            <Modal 
              buttonText={this.props.internalization.buttons.back} 
              showBackButton={true} 
              backAction={e => {
                if (
                  e.target.className === "modal-wrapper" ||
                  (e.target.closest(".modal-header") !== null &&
                    e.target.closest(".modal-header").className.includes("modal-header"))
                ) {
                  this.setState({ showInvitationModal: false });
                }
              }}>
              <ProfilePermission 
                insurance_user={this.props.insurance_user} 
                language={{edit: this.props.profile.access.edit, visualize: this.props.profile.access.visualize}} 
                idInvitation={this.state.idInvitation} 
                delete_word_not_match={this.props.profile.access.delete_word_not_match} 
                closeModal={() => this._closeModal()}
                showDeleteUserModal={(invitation_id, showDeleteUserModalData) => {
                  this.setState(prevState => ({
                    deleteGuestModalData : {invitation_id, showDeleteUserModalData}
                  }))
                }}
                showSuccesUpdateDataModal={()  => this.setState({showSuccesUpdateDataModal: true})}
                showProfilePermissionAlert={() =>  this.setState({showProfilePermissionAlert: true})}
              />
            </Modal>
        }
        { this.state.confirmChanges &&
         <AlertModal
            isShowed={this.state.confirmChanges}
            title={"This will change you configuration"}
            msg={"Are you sure?"}
            action={() => {
              this._saveAccess(true) 
            }}
            textButton={this.state.processing ? "Loading..." : this.props.internalization.buttons.ok}
            cancelButton={true}
            cancelTextButton={this.props.internalization.buttons.cancel}
            actionCancelButton={() => {
              this.setState({ processing: false, confirmChanges: false })
            }}
          />
        }

        {
          Object.entries(this.state.deleteGuestModalData).length !== 0 && (
            <ModalDeleteUser 
              id={this.state.deleteGuestModalData.invitation_id} 
              delete_word_not_match={this.props.profile.access.delete_word_not_match} 
              buttons={this.props.internalization.buttons} 
              delete_modal={this.state.deleteGuestModalData.showDeleteUserModalData} 
              hideModal={(edited) => this.setState({
                deleteGuestModalData: {},
                showProfilePermissionAlert: false,
                showDeleteUserModal: false,
                showInvitationModal: false, 
              }, () => edited && this._success())}
            />
          )
        }

        {this.state.showSuccesUpdateDataModal && (
          <AlertModal
            isShowed={this.state.showSuccesUpdateDataModal}
            title={this.props.internalization.modals.invitation.updated.title}
            msg={this.props.internalization.modals.invitation.updated.body}
            action={() => {
              this.setState({
                showSuccesUpdateDataModal: false,
                modal_action:""
              });
            }}
            textButton={this.props.internalization.buttons.ok}
          />
        )}

        {this.state.showProfilePermissionAlert &&
          <AlertModal
            isShowed={this.state.showProfilePermissionAlert}
            title={this.props.internalization.modals.invitation.delete.title}
            msg={this.props.internalization.modals.invitation.delete.body}
            action={() => {
              if(this.state.modal_action != "") {
                this.state.modal_action() 
              } else  {
                this._confirmDelete()
              }  
            }}
            textButton={this.props.internalization.buttons.ok}
            cancelButton={this.state.modal_action === ""}
            cancelTextButton={this.props.internalization.buttons.cancel}
            actionCancelButton={() => {
              this.setState({
                showProfilePermissionAlert: false,
                modal_action: ""
              });
            }}
          />
        }
        {/* @ProfilePermission modals */}


        {this.state.showSuccesModal && (
          <AlertModal
            isShowed={this.state.showSuccesModal}
            title={this.state.modal_title}
            msg={this.state.modal_body}
            action={() => {
              this.setState({
                showSuccesModal: false,
                editing: false,
                user_attributes: {},
              });
            }}
            textButton="OK"
          />
        )}
        {this.state.showActionModal && (
          <AlertModal
            isShowed={this.state.showActionModal}
            title={this.state.modal_title}
            msg={this.state.modal_body}
            action={() => this.state.modal_action()}
            textButton="Yes"
            textButtonAlertColor={true}
            cancelButton={true}
            cancelTextButton="Cancel"
            actionCancelButton={() => {
              this.setState({
                showActionModal: false
              });
            }}
          />
        )}

        <div className={this.state.screen_step === "access" ? "wrapper-profile wrapper-profile--full-width" : "wrapper-profile"}>
          {
            (this.props.profile !== undefined && 
              this.props.profile.tabs &&
              this.props.profile.tabs.length > 0) && (
              <Tabs 
                tabs={this.props.profile.tabs} 
                onClick={(tab, index) => this._tabPressed(tab, index)} 
                tabActive={this.state.screen_step_index}
                location={this.props.location}
              />
            )
          }
          {/*
            this.props.profile !== undefined && this.state.screen_step === "index" &&

              <div className="c-tabs__content is-selected">
                <div className={'wrapper-index-profile is-active'} style={{maxWidth: 768}}>
                  <div className="content-index-profile">
                    <div className="container-info-profile">
                      <div className="wrapper-avatar medium space">
                        <img
                          className="avatar bordered"
                          src={current_image}
                          alt=""
                        />
                      </div>
                      <div className="wrapper-user">
                        <div className="user-data">
                          <p className="headingH4">{this.props.user.name} {this.props.user.surname}</p>
                          <p className="body grey-dark truncate">
                            <span className="text">ID Bulltick:</span>
                            {this.props.user.id_bulltick}
                          </p>
                        </div>
                      </div>
                    </div>
                    {
                      this.props.profile && Object.keys(this.props.profile).length > 0 && this.props.profile.info.map((element, index) => {
                        return(
                          <p style={{cursor: index !== 0 ? 'pointer' : 'inherit'}} onClick={() => this._goFromInfo(element)} className="separation o-color--blue-dark">
                            <span className="headingH5">{element.label}: </span>
                            {element.value}
                          </p>
                        )
                      })
                    }                
                  </div>
                </div>
              </div>
          */}

          {
            this.state.screen_step === "manuals" && this.props.profile !== undefined && 
              <div className="c-tabs__content is-selected" style={{maxWidth: '100%'}}>
                <div style={{width: '100%' }}>
                  <Table 
                    dataTable={this.props.profile.user_manuals.table} 
                    action={{
                      title: "Action", 
                      show: (el) => window.open(el.url)
                    }}
                    search={(text)=> console.log(text)}
                  />
                </div>
              </div>
          }

          {!this.state.loading && this.state.screen_step === "profile" && (
            <div className="c-tabs__content is-selected c-tabs__content--profile" >
              {
                this.state.editing ?
                  <>
                    <CustomAvatar text={{defaultAvatars: this.props.internalization.buttons.default_avatars, yourSelection: this.props.internalization.buttons.your_selection}} disabled={false} avatar_user={this.props.user.avatar_user} newImage={(value, type)=> this._newImage(value,type)} size="medium" space={true}/>
                    <div className="wrapper-form-item">
                      <p className="input-label">ID Bulltick</p>
                      <p className="body medium">
                        {this.props.user.id_bulltick}
                      </p>
                    </div>
                  </>
                  :
                  <div className="container-info-profile">
                    <div className="wrapper-avatar medium space">
                      <img
                        className="avatar bordered"
                        src={current_image}
                        alt=""
                      />
                    </div>
                    <div className="wrapper-user">
                      <div className="user-data">
                        <p className="headingH4">{this.props.user.name} {this.props.user.surname}</p>
                        <p className="body grey-dark truncate">
                          <span className="text">ID Bulltick:</span>
                          {this.props.user.id_bulltick}
                        </p>
                      </div>
                    </div>
                  </div>
              }
              <div style={{marginBottom: 20}}>
              {
                !this.state.editing && this.props.profile && Object.keys(this.props.profile).length > 0 && this.props.profile.info.map((element, index) => {
                  return(
                    <p style={{cursor: index !== 0 ? 'pointer' : 'inherit'}} onClick={() => this._goFromInfo(element)} className="separation o-color--blue-dark">
                      <span className="headingH5">{element.label}: </span>
                      {element.value}
                    </p>
                  )
                })
              }
              </div>

              {
                this.props.profile && Object.keys(this.props.profile).length > 0 && inputs.map((item, index) => {
                  let hasTooltip = item.tooltip ? item.tooltip.length > 0 : false;
                  if(item.id !== 'language'){
                    return(
                      <div className="wrapper-form-item" onClick={ () => this.state.editing && ["phone_number", "email","pin","password"].includes(item.id) ? this.goToEdit(item) : {}}>
                        <span className="input-label">{item.label}</span>
                        <Input
                          colorGrey={true}
                          key={item.id +  "_" + this.state.show_input_ofuscated[item.id] }
                          name={item.id}
                          type={item.id === 'pin'  ?  'text' : item.type}
                          defaultValue={this.state.show_input_ofuscated[item.id] ? this._ofuscated(item.id, item.value) : item.value}
                          onChangeInput={e => this._getInputValues(this.state, e)}
                          // onFocusInput={e => _focusedInput(e, element)}
                          // onBlurInput={e => _bluredInput(e, element)}
                          value={this.state.show_input_ofuscated[item.id] ? this._ofuscated(item.id, item.value) : item.value}
                          onFocus={() => {}}
                          onBlur={() => {}}
                          disabled={!this.state.editing}
                          showErrorLabel={false}
                          //showFormatError={formFormatErrors[element.id]}
                          errorLabel={"Este campo no puede estar vacío"}
                          withButtonIcon={item.id !== 'password' ? true : false}
                          iconName={["phone_number", "email","pin","password"].includes(item.id) && "Eye"}
                          colorIcon={'#408197'}
                          hasTooltip={hasTooltip}
                          helperTooltip={[item.label, item.tooltip]}
                          slashed={!this.state.show_input_ofuscated[item.id] ? true : false}
                          buttonIconAction={e => this._disableOfuscated(e,item.id,this.state)}
                        />
                        {
                          item.id == "password" &&
                            <span  className="label hint">{this.props.profile.password_expire_days}</span>
                        }

                      </div>
                    )
                  }else {
                    return(
                      <div key={index} className="wrapper-form-item">

                        <SelectMultiple
                          colorGrey={true}
                          setRef={ref => (this.selectMultiple = ref)}
                          defaultValue={this.state.user_attributes["language"] === undefined ? this._getLanguageLegible(item.value) : this._getLanguageLegible(this.state.user_attributes["language"]) }
                          label={item.label}
                          options={item.options}
                          multiple={false}
                          openOptions={() =>
                              this.setState({
                                modalOptions: !this.state.modalOptions
                              })
                          }
                          disabled={!this.state.editing}
                          modalOptions={this.state.modalOptions}
                          getOptions={ items => this._getOptions(items, this.state, 'user_attributes')}
                          // itemsSelected={
                          //   this.state.inputsValue.type !== undefined
                          //     ? this.state.inputsValue.type
                          //     : []
                          // }
                          language={true}
                        />
                      </div>
                    )
                  }

                })
              }
              {
                !this.state.editing &&
                  <div className="profile--text-info">
                    {
                      this.props.profile!== undefined && Object.keys(this.props.profile).length > 0 && this.props.profile.extra_info.map((element, index) => {
                        return(
                          <p className="text-info">{`${element.label}: ${element.value}`}</p>
                        )
                      })
                    }
                  </div>
              }
              {
                this.state.editing ?
                  <div className="profile-edit-buttons">
                    <button
                      onClick={() => this.setState({editing: false})}
                      className="btn secondary"

                    >
                      <span className="btn-text">{this.props.internalization.buttons && this.props.internalization.buttons.cancel}</span>
                    </button>
                    <button
                      onClick={() => this._save(this.state)}
                      className="btn primary"
                      disabled={
                        !this.state.avatar_changed &&
                          Object.keys(this.state.user_attributes).length === 0
                      }
                    >
                      <span className="btn-text">{this.props.internalization.buttons && this.props.internalization.buttons.save}</span>
                    </button>
                  </div>
                  :

                  <button
                    onClick={() => this.setState({editing: true})}
                    className="btn primary"

                  >
                    <span className="btn-text">{this.props.internalization && this.props.internalization.buttons ? this.props.internalization.buttons.edit : ""}</span>
                  </button>

              }

            </div>

          )}
          { this.props.profile !== undefined && this.state.screen_step === "access" && (

            <div className="c-tabs__content is-selected">
              <div className="wrapper-access">
                <p style={{textAlign: "center"}}className="headingH3">{this.props.profile.access.description_text}</p>
                <button className="btn primary" onClick={()=> this._openInvitation(this.state)}>
                  <span className="btn-text">{this.props.profile.access.new_invitation_text}</span>
                </button>
                <div className={`container-form ${this.state.classOpen}`}>
                  {
                    this.props.profile.access.form.add_invitation.inputs.map((item,index) =>{

                      if(item.type !== "checkbox" && item.id !== "language" && item.id !== 'phone_number'){
                        return(
                          <React.Fragment key={`invitation-${this.state.invitationCont}-${item.id}`}>
                            <div className="wrapper-form-item">
                              <span className="input-label">{item.label}</span>
                              <Input
                                colorGrey={true}
                                name={item.name}
                                type={item.type}
                                defaultValue={this.state.invitation_attributes[item.name] === undefined ? "" :  this.state.invitation_attributes[item.name]}
                                value={this.state.invitation_attributes[item.name] === undefined ? "" :  this.state.invitation_attributes[item.name]}
                                onChangeInput={e => this._getInputValues(this.state, e)}
                                onFocus={() => {}}
                                onBlur={() => {}}
                                showErrorLabel={false}
                                errorLabel={"Este campo no puede estar vacío"}
                              />
                            </div>

                          </React.Fragment>
                        )
                      }else if(item.id === "language"){

                        return(
                          <div key={`invitation-${this.state.invitationCont}-${item.id}`} className="wrapper-form-item">

                            <SelectMultiple
                              colorGrey={true}
                              setRef={ref => (this.selectMultiple = ref)}
                              defaultValue={this.state.invitation_attributes["language"] === undefined ? "Select" : this._getLanguageLegible(this.state.invitation_attributes["language"])}
                              label={item.label}
                              options={item.options}
                              multiple={false}
                              openOptions={() =>
                                  this.setState({
                                    modalOptions: !this.state.modalOptions
                                  })
                              }
                              disabled={false}
                              modalOptions={this.state.modalOptions}
                              getOptions={ items => this._getOptions(items, this.state, 'invitation_attributes')}
                              language={true}
                              // itemsSelected={
                              //   this.state.inputsValue.type !== undefined
                              //     ? this.state.inputsValue.type
                              //     : []
                              // }
                            />

                          </div>
                        )
                      }else if(item.id === "phone_number"){
                        return(
                          <React.Fragment key={`invitation-${this.state.invitationCont}-${item.id}`}>
                            <div className="wrapper-form-item">
                              <span className="input-label">{item.label}</span>
                              <PhoneInput
                                //placeholder="Enter phone number"
                                value={""}
                                className=" wrapper-input grey"
                                onChange={ value => 
                                    this.setState({
                                      invitation_attributes: {
                                        ...this.state.invitation_attributes,
                                        ["phone_number"]: value
                                      }
                                    })
                                } />
                            </div>
                            <div className="wrapper-form-item">
                              <span className="input-label">{internalization[languageUserAgent].phone_number_confirmation}</span>
                              <PhoneInput
                                //placeholder="Enter phone number"
                                value={""}
                                className=" wrapper-input grey"
                                onChange={ value => 
                                    this.setState({
                                      invitation_attributes: {
                                        ...this.state.invitation_attributes,
                                        ["phone_confirmation"]: value
                                      }
                                    })
                                } />
                              { this._checkPhoneConfirmation(languageUserAgent) }

                            </div>
                          </React.Fragment>
                        )
                      }
                    })
                  }



                  {
                    this.props.insurance_user !== true &&
                      <Invitations language_user={this.props.user.language} editable={true} invitationAttributes={this.state.checkboxes} new_invitation_values={ this.state.new_invitation_values } setInvitationAttributesValues={(new_invitation_values) =>this.setState({new_invitation_values: new_invitation_values}) } permissions_selected={this.state.permissions_selected} setInvitationAttributes={(permissions_selected) => this.setState({permissions_selected: permissions_selected})} language={{edit: this.props.profile.access.edit, visualize: this.props.profile.access.visualize}}/>
                  }

                  <button
                    className="btn primary"
                    disabled={
                      !this._checkInputInvitations(languageUserAgent)
                    }
                    onClick={() =>
                        this._sendInvitation(this.state)
                    }
                  >
                    <span className="btn-text">{this.props.profile.access.send_invitation_text}</span>
                  </button>
                </div>
                {
                  Object.keys(this.props.profile).length > 0 && 
                    <div className="flex">
                      <TableBlock
                        notScroll={true}
                        data={this.props.profile.access.table}
                        history={this.props.history}
                        tableProfileAccess={true}
                        openEditInvitations={element => {
                          this.setState({
                            idInvitation: element.id,
                            showInvitationModal: true
                          })
                        }}
                        openResendInvitation={(item) => {
                          this.setState(
                            { showActionModal: true, 
                              modal_title: "Resend invitation", 
                              modal_body: "Are you sure you want to resend invitation?", modal_action: () => this.props.resendInvitation(item.id,this.props.authentication_token, (responseJSON) => this._successResdendInvitation(), () => console.log("error"))}
                          )}}
                      />
                    </div>
                }
              </div>
            </div>
          )}
          { this.props.profile !== undefined && this.state.screen_step === "cm" && (
            <div className="c-tabs__content is-selected">
              <p className="headingH3" style={{maxWidth: 500, margin: 'auto'}}>{this.props.profile.cm.description_text}</p>
              <AssetsList
                history={this.props.history}
                cm={this.props.profile.cm}
                actions={this.props.profile.actions}
                add_translation={this.props.internalization.buttons.add}
                show_translation={this.props.internalization.buttons.show}
                from={"content_manager"}
                setSpecialParent={(special_parent) => this.props.setSpecialParent(special_parent)}
                language={this.props.user.language}
              />
            </div>
          )}

          {
            this.state.screen_step === "privacy" &&

              <div className="c-tabs__content is-selected">
                <div className="settings">
                  <p className="headingH3">{this.props.profile.privacy.title}</p> 
                  <div className="block__privacy">
                    <p className="text">{this.props.profile.privacy.description} <a target="_blank" href={this.props.profile.privacy.privacy_url}>{this.props.profile.privacy.privacy_url_title}</a>.</p>
                    <div className="block__privacy--content">
                      <p className="headingH4">{this.props.profile.privacy.bulltick_access_title}</p>
                      <p className="text">{this.props.profile.privacy.bulltick_access_description}</p>
                      <div>
                      <LinkBlock
                        key={"bulltick_access_0"}
                        id={"bulltick_access"}
                        className={""}
                        balance={false}
                        date={""}
                        description={this.props.profile.privacy.checkbox_access_title}
                        action={() =>  {this.setState({accessPrivacy:{acceptance_bulltick_access: !this.state.accessCheck}, accessCheck: !this.state.accessCheck})}}
                        checkBox={true}
                        editable={this.state.editPrivacy}
                        isChecked={this.state.accessCheck}

                      />
                      </div>
                      <button className="btn primary" onClick={()=> {this.state.editPrivacy ? this._saveAccess(false) : this.setState({editPrivacy: true})}}>
                        <span className="btn-text">{this.state.editPrivacy ? this.props.internalization.buttons.save : this.props.internalization.buttons.edit}</span>
                      </button>
                    </div>
                    {
                      Object.keys(this.props.profile).length > 0 && this.state.accessCheck && Object.keys(this.props.profile.mirrors).length > 0 &&
                        <div className="block__privacy--content">
                          <p className="headingH4">{this.props.profile.privacy.mirrors_title}</p>
                          <p className="text">{this.props.profile.privacy.mirrors_description}</p>
                          {
                            Object.keys(this.props.profile).length > 0 && 
                              <div className="flex">
                                <TableBlock
                                  notScroll={true}
                                  data={this.props.profile.mirrors.table}
                                  history={this.props.history}
                                  tableProfileAccess={true}
                                  openEditInvitations={element => {
                                    this.setState({
                                      idInvitation: element.id,
                                      showInvitationModal: true
                                    })
                                  }}
                                  openResendInvitation={(item) => {
                                    this.setState(
                                      { showActionModal: true, 
                                        modal_title: "Resend invitation", 
                                        modal_body: "Are you sure you want to resend invitation?", modal_action: () => this.props.resendInvitation(item.id,this.props.authentication_token, (responseJSON) => this._successResdendInvitation(), () => console.log("error"))}
                                    )}}
                                />
                              </div>
                          }                    
                        </div>                      
                    }                 
                    <div className="block__privacy--content">
                      <p className="headingH4">{this.props.profile.privacy.bulltick_delete_account_title}</p>
                      <p className="text">{this.props.profile.privacy.description2}</p>
                      <button className="btn primary" onClick={()=> this.setState({showDeleteUserModal: true, showDeleteUserModalData: this.props.profile.privacy.delete_modal})}>
                        <span className="btn-text">{this.props.profile.privacy.button}</span>
                      </button>
                    </div>                 
                  </div>
                </div>
              </div>

          }
          {
            this.state.showDeleteUserModal &&
              <ModalDeleteUser 
                id={this.props.user.id} 
                delete_word_not_match={this.props.profile.access.delete_word_not_match} 
                delete_modal={this.state.showDeleteUserModalData} 
                hideModal={(edited) => this.setState({deleteGuestModalData: {}, showDeleteUserModal: false})}
                buttons={this.props.internalization.buttons}
              />
          }

          {this.state.screen_step === "alerts" && 
              <div className="c-tabs__content is-selected">
              <span>Alerts</span>
            </div>

          }
          { this.props.profile !== undefined && this.state.screen_step === "settings" && (
            <div className="c-tabs__content is-selected">
              <div className="settings">
                <p className="headingH3">{this.props.profile.settings.description_text}</p>            
                {
                  this.props.profile.settings.data.map((element, index) => {
                    return (
                      <LinkBlock
                        key={element.id+"_"+index}
                        id={element.id}
                        className={""}
                        balance={false}
                        date={""}
                        description={element.title}
                        action={() =>  this.setState({ user_attributes: { ...this.state.user_attributes, [element.id]: this.state.user_attributes[element.id] === undefined ? !element.value :  !this.state.user_attributes[element.id]} }) }
                        checkBox={true}
                        editable={true}
                        isChecked={element.value}

                      />
                    )
                  })
                }
                <button
                  onClick={() => this._saveAuthorization(this.state)}
                  className="btn primary"
                  disabled={
                    Object.keys(this.state.user_attributes).length === 0
                  }
                >
                  <span className="btn-text">{this.props.internalization.buttons.save}</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
    user: state.session.user,
    profile: state.session.profile,
    internalization: state.session.internalization,
    insurance_user: state.session.insurance_user,
    assigned_users: state.session.assigned_users
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateUser: (authentication_token, attributes, success, error) =>
    dispatch(updateUser(authentication_token, attributes, success, error)),
    getUser: (authentication_token, success, error) =>
    dispatch(getUser(authentication_token, success, error)),
    createUserInvitation: (attributes, authentication_token, success, error) => dispatch(createUserInvitation(attributes, authentication_token, success, error)),
    getInvitation: (id, authentication_token, success, error) =>
    dispatch(getInvitation(id, authentication_token, success, error)),
    deleteInvitation: (id, authentication_token, success, error) => dispatch(deleteInvitation(id, authentication_token, success, error)),
    updateInvitation: (id, attributes, authentication_token, success, error) =>
    dispatch(
      updateInvitation(id, attributes, authentication_token, success, error)
    ),
    getProfileCM: (authentication_token, success, error) => dispatch(getProfileCM(authentication_token, success, error)),
    resendInvitation: (id, authentication_token, success, error) =>
    dispatch(
      resendInvitation(id, authentication_token, success, error)
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ProfileEditScreen
);
