import { useState, useEffect, useRef, useCallback, Suspense } from "react";
import { connect } from "react-redux";
import Loading from "../../components/loading";
import DashboardTable from "./dashboardTable";
import Hero from "./hero";
import { getDashboard } from "../../actions/dashboard";

const Dashboard = (props) => {
  const { language: lang } = props.match.internalization;

  const [loading, setLoading] = useState(true);
  const [lastMarketClose, setLastMarketClose] = useState({});
  const [introCards, setIntroCards] = useState([]);
  const [introCardsClients, setIntroCardsClients] = useState([]);
  const [pieHighChartData, setPieHighChartData] = useState([]);
  const [pieAssetClassData, setPieAssetClassData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tooltips, setTooltips] = useState({});

  const fetchDashboard = useCallback(() => {
    props
      .getDashboard(props.authentication_token)
      .then((data) => successDashboard(data))
      .catch((err) => errorDashboard(err))
      .finally(() => finallyDashboard());
  }, [props.getDashboard, props.authentication_token]);


  useEffect(() => {
    fetchDashboard();
    hideHeaderMenu();
    return () => hideHeaderMenu(true)
  }, [fetchDashboard]);

  // Hide burger menu items
  useEffect(() => {
    document.querySelector(".burger-menu").style.display = "block"
    const sidebarContainer = document.querySelector(".wrapper-sidebar .container")
    const sidebarItems = document.querySelector(".sidebar-items")
    sidebarContainer.style.justifyContent = "flex-start"
    sidebarItems.style.display = "none"
    return () => {
      sidebarContainer.style.justifyContent = "space-between"
      sidebarItems.style.display = "flex"
    } 
  }, [])

  // Create new configuation submenu
  useEffect(() => {
    const prefooter = document.querySelector(".sidebar-items.prefooter")
    const firstSeparator = prefooter.querySelector(".item-separator")

    // create new items
    const divBlur = '<button class="wrapper-item blur-button"><p class="title1 item-text blur-text" style="padding-left: 24px;">Blur</p></button><div class="item-separator" style="width: 100%;"></div>'
    const divProfile = '<button class="wrapper-item profile-button"><p class="title1 item-text" style="padding-left: 24px;">Perfil</p></button><div class="item-separator" style="width: 100%;"></div>'
    const divManuals = '<button class="wrapper-item manuals-button"><p class="title1 item-text" style="padding-left: 24px;">Manuales</p></button><div class="item-separator" style="width: 100%;"></div>'
    const configSubmenuContainer = `<div class="config-submenu" style="width: 80%; margin-left: auto; margin-right: 10%;">${[divBlur, divProfile, divManuals].join('')}</div>`

    firstSeparator.insertAdjacentHTML('afterend', configSubmenuContainer)

    window.addEventListener('click', (e) => toggleBlur(e))

    document.querySelector(".blur-button").addEventListener('click', handleBlur)
    document.querySelector(".profile-button").addEventListener("click", goToProfile);
    document.querySelector(".manuals-button").addEventListener("click", goToManuals);

    document.querySelector(".prefooter.info-settings > button").style.pointerEvents = "none"

    return () => {
      document.querySelector(".blur-button").removeEventListener("click", handleBlur);
      document.querySelector(".profile-button").removeEventListener("click", goToProfile);
      document.querySelector(".manuals-button").removeEventListener("click", goToManuals);
      document.querySelector(".config-submenu").remove()
    };
  }, [])

  const checkBlurActive = () => {
    const blurText = document.querySelector('.blur-text')
    const isBlur = localStorage.getItem("isBlur")
    if (isBlur) {
      document.querySelector('.dashboard').classList.add("is-blur")
      blurText.innerHTML = 'Unblur'
    } 
  }

  const toggleBlur = (e) => {

    // check if element clicked is the username to stop it
    if (e.target.dataset.user !== undefined ) return false 

    const dashboardIsBlur = document.querySelector('.dashboard.is-blur')

    // if it's not blur, do nothing
    if (!dashboardIsBlur) return;

    // Get if element filter is blur
    const elBlurred = window.getComputedStyle(e.target).getPropertyValue("filter").includes('blur')
    // Check if element is inside of any highcharts
    const elBlurredInsideHighchart = [...e.target.classList].some(cl => cl.includes('highcharts')) 
    const isWealthMap = e.target.closest('.wealth-map__wrapper')

    if (elBlurredInsideHighchart) {
      // If element is inside of highchart, toggle chart's blur
      if (e.target.closest('.c-charts')) e.target.closest('.c-charts').classList.toggle('no-blur')
      if (e.target.closest('.wrapper-barsGraphic')) e.target.closest('.wrapper-barsGraphic').classList.toggle('no-blur')
    } else if (isWealthMap) {
      isWealthMap.classList.toggle('no-blur')
    } else {
      // Toggle blur on element
      elBlurred && e.target.classList.toggle('no-blur')
    }
  }

  const handleBlur = () => {
    const isBlur = localStorage.getItem("isBlur")

    if (!isBlur) {
      localStorage.setItem("isBlur", true)
    } else {
      localStorage.removeItem("isBlur")
    } 


    // Remove no-blur class from any element
    const itemWithNoBlur = document.querySelectorAll('.no-blur')
    itemWithNoBlur.forEach(item => item.classList.remove('no-blur'))

    const dashboard = document.querySelector('.dashboard')
    const blurText = document.querySelector('.blur-text')

    if (blurText.innerHTML === 'Blur') {
      blurText.innerHTML = 'Unblur'
    } else {
      blurText.innerHTML = 'Blur'
    }
    dashboard.classList.toggle("is-blur")
  }

  const goToProfile = () => {
    window.location.href = "/pro/profile"
  }

  const goToManuals = () => {
    window.location.href = "/pro/profile?code=manuals"
  }

  const hideHeaderMenu = (leaving) => {
    const menu = document.querySelector(".c-header__content-bottom")
    if(leaving) {
      menu.style.display = "flex";
    } else {
      menu.style.display = "none";
    }
  }

  const successDashboard = (data) => {
    mappedData(data);
    props.getBreadcrumbs({
      assetUrl: "/pro",
      url: "/pro",
      assetTitle: "Dashboard",
    });
  };

  const errorDashboard = (error) => {
    window.location.href = '/'
  };

  const finallyDashboard = () => {
    setLoading(false);
  };

  const mappedData = (data) => {
    // Last market close
    setLastMarketClose(data.market_closed);

    // Intro cards
    setIntroCardsClients([
      { clients: data.clients },
      { actity_last_30_days: data.actity_last_30_days },
      { all_clients_registered: data.all_clients_registered }
    ]);
    setIntroCards([
      { total_aum: data.total_aum },
      { total_financial_aum: data.total_financial_aum },
      { average_size: data.average_size },
    ]);

    // Pie High Chart
    setPieHighChartData(data.pie_high_charts.data);
    setPieAssetClassData(data.pie_asset_class);

    // Table
    setTableHeaders(data.headers);
    setTableData(data.clients_table);

    // Tooltips
    setTooltips(data.clients_tooltips);
  };

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      setTimeout(() => {
        checkBlurActive()
      }, 100)
    }
  }, [tableData])

  if(loading) {
    return <Loading />
  }

  return (
    <div className="dashboard">
      <div className="main-wrapper market">

        <Hero 
          lastMarketClose={lastMarketClose}
          introCards={introCards}
          introCardsClients={introCardsClients}
          lang={lang}
          pieHighChartData={pieHighChartData}
          pieAssetClassData={pieAssetClassData}
        />

        {tableData.length > 0 && (
          <DashboardTable
            data={tableData}
            headers={tableHeaders}
            history={props.history}
            location={props.location}
            lang={lang}
            tooltips={tooltips}
          />
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDashboard: (authentication_token, success, error) =>
      dispatch(getDashboard(authentication_token, success, error)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
