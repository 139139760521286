import React, { forwardRef, memo, useRef, useState, useEffect } from "react";
import Loading from "../../components/loading";
import BarsGraphic from "../../components/barsGraphic";
import LineHighChart from "../../components/LineHighChart";
import NestedListOwnership from "../../components/nestedListOwnership";
import PieHighcharts from "../../components/PieHighcharts";
// import TableRecentOrdersWithLink from "../../components/TableRecentOrdersWithLink";
import TableRecentOrders from "../../components/TableRecentOrders";
import WealthMap from "./wealthMap";
import Icon from "../../components/icons";
import { Interaction } from "@fullcalendar/core";

const DashboardTableBodyRowContent = forwardRef((props, ref) => {
  const {
    indexRow,
    netWorthEvolutionLoading,
    selectedRowIndex,
    filteredDataBarsGraph,
    filteredBarsFinancialAction,
    financialAssetEvolution,
    setFinancialAssetEvolution,
    filtersInternalization,
    filteredCall,
    ownershipAction,
    pieSelected,
    isLoading,
    onChangeNote,
    onDestroyNote,
    onSaveNote,
    user,
    internalization,
    location,
    history,
    storedData,
    itemId,
  } = props;

  const [showNotes, setShowNotes] = useState(false);
  const [contentData, setContentData] = useState({});


  useEffect(() => {
    const data = storedData.find(o => o[itemId])
    if (data) setContentData(data[itemId])  
  }, [storedData]);

  const notesContainerRef = useRef(null);

  return (
    <div className="c-table-dashboard__row-content-wrapper" ref={ref}>

      { Object.keys(contentData).length > 0
         ? ( 
           <div className="c-table-dashboard__row-content"> 
             {/* Info */} 
             <div 
               className="c-table-dashboard__row-content-charts" 
               style={{ flexDirection: "column", marginBottom: 20 }} 
             > 
               <h2 className="headingH2">{contentData.loaded_items.title}</h2> 
               <div 
                 style={{ 
                   paddingBottom: 24, 
                 }} 
               > 
                 <div 
                   className="c-table-dashboard__loaded-items" 
                   style={{ 
                     display: "grid", 
                     gridTemplateColumns: "repeat(3, minmax(250px, 1fr))", 
                   }} 
                 > 
                   {contentData.loaded_items.data.map((item, index) => { 
                     return ( 
                       <div 
                         key={`loaded-items-${index}`} 
                         style={{ 
                           display: "flex", 
                           alignItems: "center", 
                           height: 24, 
                         }} 
                       > 
                         <strong> {item.title}: </strong> 
                         {Object.values(item)[0] === true ? ( 
                           <Icon name="Check" width={14} /> 
                         ) : ( 
                             <Icon name="Close" width={14} color="#f3b01c" /> 
                           )} 
                       </div> 
                     ); 
                   })} 
                 </div> 
               </div> 
               </div> 

             <div className="c-table-dashboard__row-content-charts"> 
               <div style={{ width: "49%" }}> 
                 <h2 className="headingH2"> 
                   {contentData.net_worth_data.bars_graphic_data.title} 
                 </h2> 

                 {Object.keys(contentData.net_worth_data.bars_graphic_data).length > 0 && ( 
                   <BarsGraphic 
                     data={ 
                       selectedRowIndex === indexRow && 
                         Object.keys(filteredDataBarsGraph).length > 0 
                         ? filteredDataBarsGraph 
                         : contentData.net_worth_data.bars_graphic_data 
                     } 
                     filterAction={(url) => 
                       filteredBarsFinancialAction(url, indexRow) 
                     } 
                     xTickValues={ 
                       selectedRowIndex === indexRow && 
                         Object.keys(filteredDataBarsGraph).length > 0 
                         ? filteredDataBarsGraph.type.pasive.map((elem) => elem.x) 
                         : contentData.net_worth_data.bars_graphic_data.type.pasive.map( 
                           (elem) => elem.x, 
                         ) 
                     } 
                     yTickValues={ 
                       selectedRowIndex === indexRow && 
                         Object.keys(filteredDataBarsGraph).length > 0 
                         ? filteredDataBarsGraph.type.pasive.map((elem) => elem.y) 
                         : contentData.net_worth_data.bars_graphic_data.type.pasive.map( 
                           (elem) => elem.y, 
                         ) 
                     } 
                   /> 
                 )} 
               </div> 

               {contentData.consolidated_data.consolidated_graph && 
                 contentData.custodian_data.custodian.graph_all && 
                 Object.keys(contentData.custodian_data.custodian.graph_custodian).length > 
                   0 && ( 
                   <div style={{ width: "49%" }}> 
                     <h2 className="headingH2">{contentData?.consolidated_data?.title}</h2> 
                     <div 
                       className="filter-buttons-wrapper" 
                       style={{ marginTop: 36 }} 
                     > 
                       {contentData.consolidated_data.consolidated_graph && ( 
                         <button 
                           className={`filter-buttons btn ${financialAssetEvolution === "consolidated" ? "primary" : "primary border"}`} 
                           type="button" 
                           onClick={() => setFinancialAssetEvolution("consolidated")} 
                         > 
                           <span className="btn-text">
                             {user.language.includes("es") 
                               ? "Consolidado" 
                               : "Consolidated"}
                          </span> 
                         </button> 
                       )} 

                       {contentData.custodian_data.custodian.graph_all && 
                         Object.keys(contentData.custodian_data.custodian.graph_custodian) 
                           .length > 0 && ( 
                           <button 
                             className={`filter-buttons btn ${financialAssetEvolution === "custodian" ? "primary" : "primary border"}`} 
                             type="button" 
                             onClick={() => setFinancialAssetEvolution("custodian")} 
                           > 
                             <span className="btn-text">

                             {user.language.includes("es") 
                               ? "Custodio"
                               : "Custodian"}
                            </span> 
                           </button> 
                         )} 
                     </div> 

                     {financialAssetEvolution === "consolidated" && 
                       contentData.consolidated_data.consolidated_graph && ( 
                         <LineHighChart 
                           styles={{ paddingTop: 0 }} 
                           key={"graph_t"} 
                           type="account" 
                           updateData={(itemsSelected, data_temp) => 
                             console.log(itemsSelected, data_temp) 
                           } 
                           currentData={ 
                             contentData.consolidated_data.consolidated_graph.last_month 
                           } 
                           dates={contentData.consolidated_data.consolidated_graph.dates} 
                           allCustodians={{}} 
                           accountData={contentData.consolidated_data.consolidated_graph} 
                           currentCustodianName={ 
                             user.language.includes("es") 
                               ? "Consolidado" 
                               : "Consolidated" 
                           } 
                           centerLabel={true} 
                           languageUserAgent={user.language} 
                           yAxisTitle={ 
                             user.language.includes("es") 
                               ? "Rendimiento" 
                               : "Performance" 
                           } 
                           setFilterType={(type) => console.log(type)} 
                           extra_title={{}} 
                         /> 
                       )} 

                     {financialAssetEvolution === "custodian" && 
                       contentData.custodian_data.custodian.graph_all && 
                       Object.keys(contentData.custodian_data.custodian.graph_custodian) 
                         .length > 0 && ( 
                         <LineHighChart 
                           styles={{ paddingTop: 0 }} 
                           key={"graph_t_custodian"} 
                           type="custodian" 
                           allSelected={ 
                             contentData.custodian_data.custodian.custodian_all_name 
                           } 
                           preSelected={false} 
                           updateData={(itemsSelected, data_temp) => ""} 
                           currentData={ 
                             contentData.custodian_data.custodian.graph_custodian.dates 
                             .last_month 
                           } 
                           dates={ 
                             contentData.custodian_data.custodian.graph_custodian.dates 
                           } 
                           allCustodians={contentData.custodian_data.custodian.graph_all} 
                           currentCustodianName={""} 
                           centerLabel={true} 
                           languageUserAgent={user.language} 
                           yAxisTitle={ 
                             user.language.includes("es") 
                               ? "Rendimiento" 
                               : "Performance" 
                           } 
                           extra_title={contentData.custodian_data.custodian.extra_title} 
                         /> 
                       )} 
                   </div> 
                 )} 
             </div> 

             <div 
               className="c-table-dashboard__row-content-charts" 
               style={{ flexDirection: "column", gap: 0, marginBottom: 32 }} 
             > 
               <h2 className="headingH2">{contentData.wealth_map.title}</h2> 
               {contentData.wealth_map.graph.nodeDataArray.length > 0 && ( 
                 <div className="wealth-map__wrapper"> 
                   <WealthMap 
                     location={location} 
                     history={history} 
                     wealthMapData={contentData.wealth_map} 
                   /> 
                 </div> 
               )} 
               {contentData.wealth_map.graph.nodeDataArray.length < 1 && ( 
                 <p style={{ marginTop: 32, textAlign: "center" }}>
                  { user.language.includes("es") ? 'Sin datos' : 'No data' }
                  </p> 
               )} 
             </div> 

             <div className="c-table-dashboard__row-content-charts"> 
               <div style={{ flex: 1, marginBottom: 40 }}> 
                 <h2 className="headingH2" style={{ marginBottom: 36 }}> 
                   {contentData.activity.title} 
                 </h2> 
                 {Object.keys(filtersInternalization).length > 0 && ( 
                   <TableRecentOrders 
                     dataTable={contentData.activity.data.rows} 
                     filters_internalization={filtersInternalization} 
                    filteredCall={(params) => filteredCall(params, itemId)}
                   /> 
                 )} 
               </div> 
             </div> 

             <div 
               className="c-table-dashboard__row-content-charts" 
               style={{ marginBottom: 40 }} 
             > 
               <div style={{ flex: 1 }}> 
                 <h2 className="headingH2" style={{ marginBottom: 36 }}> 
                  { contentData.ownership_data.ownership_structure.title }
                 </h2> 
                 <div 
                   style={{ 
                     maxHeight: 300, 
                     overflow: "auto", 
                     flex: 1, 
                   }} 
                 > 
                   {contentData.ownership_data.ownership_structure.data[0].descendants 
                     .length > 0 ? ( 
                       <NestedListOwnership 
                         mainClass={"nestedlist--padding"} 
                         color={"nestedlist__item--grey"} 
                         data={contentData.ownership_data.ownership_structure.data} 
                         ownership={true} 
                         action={(id, url) => ownershipAction(id, url)} 
                         levelColors={true} 
                         square={"nestedlist__item--square"} 
                       /> 
                     ) : ( 
                       <p>{contentData.ownership_data.ownership_structure.no_data_title}</p> 
                     )} 
                 </div> 
               </div> 
               <div style={{ flex: 1 }}> 
                 <h2 className="headingH2" style={{ marginBottom: 36 }}> 
                   {contentData.pie_high_chart.title} 
                 </h2> 
                 <div 
                   style={{ 
                     height: 222, 
                     maxWidth: 600, 
                     margin: "0 auto", 
                     overflowY: "hidden", 
                   }} 
                 > 
                   <div style={{ transform: "translateY(-37%)" }}> 
                     <PieHighcharts 
                       pieSelected={(pie) => pieSelected(pie)} 
                       data={contentData.pie_high_chart.graph.pie_graphic_assets.data} 
                       size={112} 
                     /> 
                   </div> 
                 </div> 
               </div> 
             </div> 

             <div 
               className="c-table-dashboard__row-content-charts" 
               style={{ flexDirection: "column" }} 
             > 
               {(contentData.notes.value !== undefined || 
                 contentData.notes.value !== null || 
                 contentData.notes.value === "") && ( 
                   <div 
                     className="c-table-dashboard__row-content-charts c-table-dashboard__notes-wrapper" 
                     style={{ flexDirection: "column" }} 
                     ref={notesContainerRef} 
                   > 
                     <h2 
                       className="headingH2" 
                       style={{ display: "flex", justifyContent: "space-between" }} 
                     > 
                      <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                        {contentData.notes.title}
                      </div>
                       <div 
                         onClick={() => { 
                           notesContainerRef.current.classList.toggle("is-active"); 
                           setShowNotes(!showNotes); 
                         }} 
                         style={{ cursor: "pointer" }} 
                       > 
                         <Icon name={!showNotes ? "Edit" : "Close"} /> 
                       </div> 
                     </h2> 

                     {isLoading && ( 
                       <Loading 
                         simple={true} 
                         text={internalization.loading} 
                         style={{ width: 90, margin: "0 auto" }} 
                       /> 
                     )} 
                    {!isLoading && (
                      <div 
                        className="c-table-dashboard__notes-container" 
                      > 
                        <textarea 
                          rows="10" 
                          maxLength={2000} 
                          onChange={(e) => onChangeNote(e)} 
                        > 
                          {contentData.notes.value} 
                        </textarea> 
                        <div className="wrapper-tabs"> 
                          <button 
                            type="button" 
                            className="btn secondary" 
                            onClick={() => onDestroyNote(itemId)} 
                            style={{ width: "fit-content" }} 
                          > 
                            <span className="btn-text">Eliminar</span> 
                          </button> 
                          <button 
                            type="button" 
                            className="btn primary" 
                            onClick={() => onSaveNote(itemId)} 
                            style={{ width: "fit-content" }} 
                          > 
                            <span className="btn-text">Guardar</span> 
                          </button> 
                        </div> 
                      </div> 
                    )}
                  
                   </div> 
                 )} 
             </div> 
           </div> 
         ) : ( 
           null 
         ) 
       } 
    </div>
  )

});

export default memo(DashboardTableBodyRowContent);
