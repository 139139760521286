// Dependencies
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Components
import Icon from "./icons/";


const Logo = (props) => {

  useEffect(() => {
    isHome();
  }, [props.url]);

  const isHome = () => {
    return window.location.pathname === "/home";
  };

  const isDashboard = () => {
    return window.location.pathname === "/pro" || window.location.pathname === "/pro/profile";
  };


  const getDimension = () => {

    if(isHome()){
      if(props.real_user_insurance){
        return {width: '125px', height: '63px'}
      }else {
        return {width: '125px', height: '51px'}
      }
    }else {
      if(props.real_user_insurance){
        return {width: '100px', height: '51px'}
      }else {
        return {width: '100px', height: '41px'}
      }
    }
  }

  const click = (e) => {
    if(window.location.pathname === "/pro/profile"){
      e.preventDefault()
      e.stopPropagation()
      window.location.href = "/pro"
    }
  }
  return (
    <div
      className={
        isHome()
          ? "c-logo c-logo--big"
          : "c-logo c-logo--small"
      }
    >
      <Link onClick={(e) => click(e)} to={window.location.pathname === "/pro" || window.location.pathname === "/pro/profile" ? "/pro" : "/home"}>
        <div>
          <Icon
            width={isDashboard() ? 100 : getDimension().width}
            height={isDashboard() ? 64 : getDimension().height}
            className={"logo"}
            name={
              props.real_user_insurance 
              ? "LogoInsurance" 
              : isDashboard() ? "LogoPro" : "Logo"
            }
            // name={props.real_user_insurance ? "LogoInsurance" : "LogoPro"}
          />
        </div>
      </Link>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    real_user_insurance: state.session.real_user_insurance,
  };
}

export default connect(mapStateToProps, null)(Logo);
