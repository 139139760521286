import React, { Component } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import {SESS_POLL_INTERVAL, SESS_EXPIRATION_MINUTES, SESS_WARNING_MINUTES, ENVIRONMENT_VAR} from '../constants'

// Actions
import { autoLogin, setCurrentBreadcrumb, destroySession, destroySessionSuccess } from "../actions/session";
import { changeMirrorName } from "../actions/profile";
import {postCleanNotificationsAdeppar} from '../actions/financial_statements'
// Helpers
import AutoLogin from "./autoLogin";
import {getDateLocalized} from './getLocale'
import {initSessionMonitor} from './checkTimeOutSession'


// Components
import VerifyPassword from '../components/verifyPassword'
import CustomModal from "../components/customModal";
import ModalChangeUser from "../components/modalChangeUser";
import Icon from '../components/icons/';

const moment = require("moment");

var getSetInterval = ""

class PrivateRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      private_token: null,
      props: this.props,
      loaded: false,
      breadcrumbs: {},
      showModal: true,
      special_parent: [],
      showModalSessionExpire: false,
      remaining_time: 0,
      remaining_check: "",
      inactivity_key: "keep_logged",
      showModalChangeUser: false
    };


  }


  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    if(ENVIRONMENT_VAR !== 'local'){
      initSessionMonitor(
        () => this._destroySession(),
        (time_left, diffMins, checkTime) =>
          this._showModalSessionExpire(time_left, diffMins, () => checkTime()),
          this.props.user.admin
      );
    }
  }

  _destroySession() {
    this.props.destroySession(
      this.props.authentication_token,
      () => console.log("out"),
      () => console.log("error")
    );
  }

  componentDidMount() {
    this._autoLogin()
    var that = this;
    document.body.onmousedown = function() { 
      if(ENVIRONMENT_VAR !== 'local'){
        initSessionMonitor(
        () => that._destroySession(),
        (time_left, diffMins, checkTime) =>
          that._showModalSessionExpire(time_left, diffMins, () => checkTime()),
          that.props.user.admin
        );
      }
    }
  }

  _autoLogin(){
    let token =
      localStorage.getItem("authentication_token") ||
      sessionStorage.getItem("authentication_token");

    let redirect_to = ""
    
    if(this.props.location && this.props.location.pathname){
      redirect_to = "?redirect_to="+this.props.location.pathname + this.props.location.search
    }
    AutoLogin(
      (token) =>
        this.props.autoLogin(
          token,
          () => {
            this._success(token);
          },
          () => this._error()
        ),
      () => (window.location.href = "/"+redirect_to)
    );
    window.addEventListener("popstate", (e) => {
      // Nope, go back to your page
      //window.location.reload()
    });
  }

  _success(private_token) {
    let special_parent = localStorage.getItem("special_parent");
    if (special_parent) {
      try {
        special_parent = JSON.parse(special_parent);
      } catch (e) {
        special_parent = [];
      }
    } else {
      special_parent = [];
    }

    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.from_sidebar === true
    ) {
      this.setState({
        private_token: private_token,
        loaded: true,
        special_parent: [],
      });
    } else {
      this.setState({
        private_token: private_token,
        loaded: true,
        special_parent: special_parent,
      });
    }
    this.props.setCurrentBreadcrumb(this._getLocalStorageCurrentBreadcrumb());
    if(ENVIRONMENT_VAR !== 'local'){
      initSessionMonitor(
        () => this._destroySession(),
        (time_left, diffMins, checkTime) =>
          this._showModalSessionExpire(time_left, diffMins, () => checkTime()),
          this.props.user.admin
      );  
    }
    
  }

  _error() {
    this.setState({ loaded: true });

    window.location.replace("/");
  }

  _getLocalStorageCurrentBreadcrumb() {
    let current_breadcrumb_localstorage = localStorage.getItem(
      "current_breadcrumb"
    );
    if (current_breadcrumb_localstorage) {
      try {
        current_breadcrumb_localstorage = JSON.parse(
          current_breadcrumb_localstorage
        );
      } catch (e) {
        current_breadcrumb_localstorage = [];
      }
    } else {
      current_breadcrumb_localstorage = [];
    }
    return current_breadcrumb_localstorage;
  }
  _showModalSessionExpire(time_left, diffMins, checkTime) {
    this.setState({
      showModalSessionExpire: true,
      inactivity_key: "keep_logged",
      remaining_time: time_left,
      remaining_check: checkTime,
    });
    this._countDown(time_left, checkTime);
  }

  _countDown(time_left, checkTime) {
    var that = this;
    let countDownDate = moment().add(time_left, "minutes");
    getSetInterval = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result in the element with id="demo"
      that.setState({ remaining_time: minutes + "m " + seconds + "s " });

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(getSetInterval);
        that.setState({ remaining_time: "0 s", inactivity_key: "logged_out" });
        localStorage.removeItem("authentication_token");
        localStorage.removeItem("current_breadcrumb");
        localStorage.removeItem("special_parent");
        sessionStorage.removeItem("authentication_token");
      }
    }, 1000);
  }

  _checkTimeExpiration(checkTime) {
    this.setState({ showModalSessionExpire: false });
    clearInterval(getSetInterval);
    checkTime();
  }

  _getBreadcrumbs(breadcrumbsElements) {
    
    this.setState({
      breadcrumbs: {
        ...this.state.breadcrumbs,
        assetUrl: breadcrumbsElements.assetUrl,
        url: breadcrumbsElements.url,
        assetTitle: breadcrumbsElements.assetTitle,
      },
    });
  }
  _setSpecailParent(special_parent) {
    this.setState({ special_parent: special_parent });
    localStorage.setItem("special_parent", JSON.stringify(special_parent));
  }

  _unauthorized() {
    this.props.destroySessionSuccess();
    window.location.href = "/";
  }

  _getNameUSer() {
    let name = ""

    this.props.assigned_users && this.props.assigned_users.length > 0 && this.props.assigned_users.map((item) => {
      if (item.current) {
        name = item["name"] + " ("+item["id_bulltick"]+")"
      }
    });

    return name
  }

  openModalChangeUser(){
    this.setState({showModalChangeUser: true})
  }

  successChangeUser(){
    window.location.href = "/home";
  }

  editMirroName(item){
    this.props.changeMirrorName(this.props.authentication_token, item.related_permit_id, item.value, () => this._autoLogin(), () => console.log("ko"))
  }

  render() {

    let Header = this.props.header;
    let Breadcrumbs = this.props.breadcrumbs;
    let Component = this.props.component;
    let Footer = this.props.footer;
    return (
      <>
        <Route
          render={(props, component) =>
            this.state.private_token === null ? (
              ""
            ) : (
              <div
                className={
                  this.props.location.pathname.split("/")[1].split("-").length <
                  2
                    ? `${
                        this.props.location.pathname.split("/")[1]
                      } full-height ${
                        this.props.location.pathname.split("/")[1] ===
                        "notifications"
                          ? "fixed"
                          : ""
                      }`
                    : `${
                        this.props.location.pathname.split("/")[1].split("-")[0]
                      } ${
                        this.props.location.pathname.split("/")[1]
                      } full-height`
                }
              >
                {
                  Header !== undefined &&
                  Object.keys(this.props.user).length > 0 && (
                    <Header 
                      user={this.props.user}
                      editProfile={() => {
                        if(window.location.pathname === "/pro/profile" || window.location.pathname === "/pro"){
                          window.location.href = "/pro/profile"
                        }else{
                          props.history.push("/profile", {
                            from_header: true,
                          })
                          }  
                        }
                      }
                      date={getDateLocalized("en-US", new Date())}
                      match={this.state.props}
                      documentsAction={() =>
                        props.history.push("/documents-index")
                      }
                      messagesAction={() => alert("click on Messages")}
                      alertsAction={() => props.history.push("/alerts")}
                      statementAction={() => {this.props.postCleanNotificationsAdeppar(this.props.authentication_token);window.location = "/balance-show?url=balance_principal/financial_assets&code=financial_statements"}}
                      searchAction={() => props.history.push("/search")}
                      notificationsAction={() =>
                        props.history.push("/notifications")
                      }
                      menu={this.props.menu.tootlips}
                      financial_statements_enabled={this.props.menu.financial_statements_enabled}
                      assignedUsers={this.props.assigned_users}
                      nameCurrentUser={this._getNameUSer()}
                      selectedUser={() => this.openModalChangeUser()}
                      {...props}
                    />
                  )
                }
                

                {Breadcrumbs !== undefined && (
                  <Breadcrumbs
                    {...props}
                    user={this.props.user}
                    match={this.state.props}
                    breadcrumbs={this.state.breadcrumbs}
                    special_parent={this.state.special_parent}
                    setSpecialParent={(special_parent) =>
                      this._setSpecailParent(special_parent)
                    }
                    setCurrentBreadcrumb={(current) =>
                      this.props.setCurrentBreadcrumb(current)
                    }
                  />
                )}
                {Breadcrumbs === undefined && (
                  <div className="wrapper-breadcrumbs"></div>
                )}
                {this.props.password_required &&
                  this.state.showModal === true && (
                    <VerifyPassword
                      errors={this.props.errors}
                      close={() => this.setState({ showModal: false })}
                    />
                  )}
                {this.props.unauthorized_request && (
                  <CustomModal
                    isShowed={this.props.unauthorized_request}
                    close={() => this._unauthorized()}
                  >
                    <p className="headingH2 block-title">
                      {this.props.internalization.alerts.unauthorized_title}
                    </p>
                    <p className="o-text-align--center body grey-dark">
                      {this.props.internalization.alerts.unauthorized_body}
                    </p>
                    <div className="modal__time--btn">
                      <button
                        onClick={() => {
                          let body = document.body;
                          body.classList.contains("has-modal") &&
                            body.classList.remove("has-modal");
                          this._unauthorized();
                        }}
                        className="btn primary"
                        // style={[{ transform: this.state.scaleButton }]}
                      >
                        <span className="btn-text">
                          {this.props.internalization.buttons.ok}
                        </span>
                      </button>
                    </div>
                  </CustomModal>
                )}
                {this.state.showModalSessionExpire === true && (
                  <CustomModal
                    isShowed={this.state.showModalSessionExpire}
                    style={{ zIndex: 999999 }}
                  >
                    <div className="modal__time">
                      <Icon
                        name="AlertInfo"
                        bgColor={"#f3b01c"}
                        color={"#FFF"}
                        style={{ width: "auto", marginBottom: 30 }}
                      />
                      <p className="headingH2 block-title">
                        {
                          this.props.internalization.alerts.inactivity[
                            this.state.inactivity_key
                          ].title
                        }
                      </p>
                      <p className="o-margin--bottom-size-l">
                        {
                          this.props.internalization.alerts.inactivity[
                            this.state.inactivity_key
                          ].body
                        }
                        {this.state.inactivity_key === "keep_logged" &&
                          this.state.remaining_time && (
                            <p>{this.state.remaining_time}</p>
                            )}{" "}
                        {this.state.inactivity_key !== "keep_logged" && (
                          <p>
                            {
                              this.props.internalization.alerts.inactivity[
                                this.state.inactivity_key
                              ].body_2
                            }{" "}
                          </p>
                        )}
                      </p>
                    </div>
                    <div className="modal__time--btn">
                      <button
                        onClick={() => {
                          let body = document.body;
                          body.classList.contains("has-modal") && body.classList.remove("has-modal");
                          this.state.inactivity_key === "keep_logged" ? this._checkTimeExpiration(this.state.remaining_check) : window.location.href = "/"
                        }}
                        className="btn primary"
                        // style={[{ transform: this.state.scaleButton }]}
                      >
                        <span className="btn-text">
                          {this.state.inactivity_key === "keep_logged"
                            ? this.props.internalization.buttons.keep_loggin
                            : this.props.internalization.buttons.ok}
                        </span>
                      </button>
                    </div>
                  </CustomModal>
                )}

                {this.state.showModalChangeUser && (
                  <ModalChangeUser
                    assignedUsers={this.props.assigned_users}
                    closeModal={() =>
                      this.setState({ showModalChangeUser: false })
                    }
                    action={() => this.successChangeUser()}
                    editMirroName={(item) => this.editMirroName(item)}
                  />
                )}

                <Component
                  {...props}
                  match={this.state.props}
                  getBreadcrumbs={(breadcrumbsElements) =>
                    this._getBreadcrumbs(breadcrumbsElements)
                  }
                  setSpecialParent={(special_parent) =>
                    this._setSpecailParent(special_parent)
                  }
                  showModalSessionExpire={(time_left, diffMins, checkTime) =>
                    this._showModalSessionExpire(time_left, diffMins, () =>
                      checkTime()
                    )
                  }
                />
                {Footer !== undefined && (
                  <Footer
                    {...props}
                    match={this.state.props}
                    menu={this.props.menu.footer}
                  />
                )}
              </div>
            )
          }
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
    user: state.session.user,
    menu: state.session.menu,
    market_closed: state.session.market_closed,
    password_required: state.session.password_change_required,
    unauthorized_request: state.session.unauthorized_request, 
    errors: state.session.errors,
    internalization: state.session.internalization,    
    insurance_user: state.session.insurance_user,
    assigned_users: state.session.assigned_users
  };
}

function mapDispatchToProps(dispatch) {
  return {
    postCleanNotificationsAdeppar: (authentication_token) => dispatch(postCleanNotificationsAdeppar(authentication_token)),
    destroySession: (authentication_token, success, error) => dispatch(destroySession(authentication_token, success, error)),
    autoLogin: (authentication_token, success, error) => dispatch(autoLogin(authentication_token, success, error)),
    setCurrentBreadcrumb: (current_bradcrumb) => dispatch(setCurrentBreadcrumb(current_bradcrumb)),
    destroySessionSuccess: () => dispatch(destroySessionSuccess()),
    changeMirrorName: (authentication_token, related_permit_id, name, success, error) => dispatch(changeMirrorName(authentication_token, related_permit_id, name, success, error))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
