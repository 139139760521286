// Dependencies
import React, { useState, useEffect, useRef } from "react";
import {
  VictoryBar,
  VictoryChart,
  VictoryStack,
  VictoryAxis,
  VictoryLegend,
  VictoryLine,
  VictoryScatter,
  VictoryZoomContainer,
  createContainer,
  VictoryLabel,
  VictoryVoronoiContainer,
  VictoryTooltip,
} from "victory";

import Icon from "./icons";
import barGraphic_fake from "../assets/images/barGraphic_fake.png";
import pieGraphick_fake from "../assets/images/pieGraphick_fake.png";
import { getValueLocalized } from "../helpers/getLocale";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";


const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi");

let key_value = 1;

function BarsGraphic(props) {
  const [active, setActive] = useState(props.data.filter_selected);
  const [parentWidth, setParentWidth] = useState(0);
  const chartRef = useRef(null);

  useEffect(() => {
    key_value = key_value + 1;
    setActive(props.data.filter_selected);
  }, [props.data.filter_selected]);

  // Check if this chart has a parent and if it has set width
  useEffect(() => {

    let parent = chartRef.current.parentNode;

    if(parent) {
      setParentWidth(parent.offsetWidth);
    }

  }, [])

  const filterAction = (code, url) => {
    props.filterAction(url);
    setActive(code);
  };



  const getValues = (type) => {
    let values_type = []
    if(props.data && props.data.type){
      
      props.data.type[type].forEach((element) => {
        if(type == "consolidated"){
          values_type.push(Math.round(element.y * 100) / 100)
        }
        else{
          values_type.push(Math.round(element.y0 * 100) / 100)  
        }
        
      })
    }
    return values_type
  }  

  const getSeries = () => {
    let series = []
    let asset_values = getValues('active')
    let pasive_values = getValues('pasive')
    if (asset_values.reduce((a, b) => a + b, 0) != 0){
      series.push({
              type: 'column',
              name: props.data.legend[1].name,
              data: asset_values
          })
    }
    if (pasive_values.reduce((a, b) => a + b, 0) != 0){
      series.push({
            type: 'column',
            name: props.data.legend[2].name,
            data: pasive_values
          })
    }
    series.push({
              type: 'spline',
              name: props.data.legend[0].name,
              data: getValues('consolidated'),
              color: '#005774',
              marker: {
                  lineWidth: 2,
                  lineColor: '#005774',
                  fillColor: 'white'
              }
          })
    return series;
  }

  return (
    <div key={"_" + key_value} ref={chartRef}>
      <div
        key={"_" + key_value + "_" + props.data.total}
        className="wrapper-barsGraphic"
      >
        {/*<div className="content-fake">
                <div className="content-fake__icon">
                    <Icon name="Lock" color="#005774"/>
                    <p className="headingH4">Not granted</p>
                </div>
                <img src={barGraphic_fake} className="content-fake__image"/>
            </div>*/}
        <span
          className={`text-total  ${
            props.data.signTotal === "negative" ? "orange" : "blue"
          }`}
        >
          {props.data.total}
        </span>
        <div className="filter-buttons-wrapper">
          {props.data.filters &&
            props.data.filters.length > 1 &&
            props.data.filters.map((item, i) => {
              return (
                <button
                  key={i}
                  onClick={() => filterAction(item.code, item.url)}
                  className={`filter-buttons btn primary ${
                    active === item.code ? "" : "border"
                  }`}
                >
                  <span className="btn-text">{item.title}</span>
                </button>
              );
            })}
        </div>
      <HighchartsReact
        containerProps={{ className: "c-charts__pie" }}
        highcharts={Highcharts}
        options={{
          title: {
            text: "",
          },
            chart: {
              type: 'line',
              zoomType: 'xy',
              width: parentWidth > 0 ? parentWidth : null,
              // height: props.height || 400,
              resetZoomButton: {
                position: {
                  // align: 'right', // by default
                  verticalAlign: 'bottom', // by default
                  x: 0,
                  y: 30,
                  relativeTo: 'chart'
                }
              }
            },

          tooltip: {

            // pointFormat: "{series.name}: <b>{getValueLocalized('en-US', point.y, true, 'currency').formated}</b><br/>",
            formatter: function () {
              return this.series.name + ': <b>' + getValueLocalized('en-US', this.y, true, 'currency', false).formated + '</b>';
            },
            useHTML: true,
            style: {
              padding: 0,
            },
          },
          accessibility: {
            point: {
              valueSuffix: "",
            },
          },
          // plotOptions: {
          //   pie: {
          //     allowPointSelect: true,
          //     cursor: "pointer",
          //     dataLabels: {
          //       enabled: true,
          //       format: "<b>{point.name}</b>: {point.percentage:.1f}",
          //     },
          //   },
          // },
          xAxis: {
              categories: props.xTickValues
          },
          // yAxis: {
          //   tickPositions: props.yTickValues
          // },
          colors: [
            "#80abba", "#f3b01c"
          ],
          credits: {
              enabled: false
          },
            series: getSeries()
        }}
      />
      </div>
    </div>
  );
}

export default BarsGraphic;
