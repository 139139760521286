// Dependencies
import React, {useState, useEffect, useRef} from 'react';

// Components
import Icon from './icons/';

const Pagination = (props) => {

  const paginationWrapperRef = useRef(null)
  const [showPages, setShowPages] = useState(props.currentPage);

  useEffect(() => {
    if(paginationWrapperRef.current) {
      paginationWrapperRef.current.classList.add('active')
    }
  }, []);


  useEffect(() => {
    setShowPages(props.currentPage)
  }, [props.currentPage]);

  if (props.currentPage === null || props.currentPage === undefined) return null

  return (

      <div className="wrapper-pagination" ref={paginationWrapperRef}>
          <div className="container-pagination">
              {
              showPages > 4 &&
                  <div onClick={() => showPages > 10 ? setShowPages(showPages - 10) : setShowPages(0) } className="pagination__item"> 
                      <Icon name="SingleArrow" orientation="left" color="white"/>
                  </div>
              }
              {
              props.pages &&
                      props.pages.slice(showPages > 4 ? (showPages - 4) :  0, showPages > 4 ? (showPages + 10) : (showPages + 14) ).map( page => (
                          <div onClick={() =>  {props.goTo(page)} } className={`pagination__item ${props.currentPage === page && "active"} `}> 
                              <span>{page}</span>
                          </div>
                      ))
              }
              {
                  (showPages + 10) < props.pages.length &&
                      <div onClick={() => (showPages + 10) < props.pages.length ? setShowPages(showPages + 10) : setShowPages(props.pages - 10) } className="pagination__item"> 
                          <Icon name="SingleArrow" orientation="right" color="white"/>
                      </div>
              }
          </div>
      </div>

  );

}

export default Pagination;
