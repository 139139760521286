import React, {useState, useEffect} from "react";
import classNames from "classnames";
import Icon from "./icons";

const ContentDropdownMenu = (props) => {

  const {tabs, changeTab} = props;

  const [showTabs, setShowTabs] = useState(false);
  const [tabActive, setTabActive] = useState(false);
  const [url, setUrl] = useState('');


  useEffect(() => {
   setTabActive(props.index)
  }, [props.index])

  const handelShowDropdownMenu = () => {
    setShowTabs(!showTabs)
  };
  
  const handleChangeTab = (tab, index) => {
    if(url === '') {
      setUrl(window.location.href);
    }

    if(tabActive === index) { // remove tab actie and go to screen content
      // setTabActive(-1);
      // changeTab(null, -1, url);
    }else {
      setTabActive(index)
      changeTab(tab, index, url);
    }
    handelShowDropdownMenu()
  }

  const handleWrapperTabClasses = (index) => {
    return classNames(
      "c-dropdown-menu__content",
      " c-tabs__dropdown",
      showTabs && "is-active"
    )
  }

  const handleTabClasses = (index) => {
    return classNames(
      "c-dropdown-menu__content-tab", 
      tabActive === index && "is-active"
    )
  }

  return (
    <>
      <button
        type="button"
        title={"Content menu"}
        onClick={handelShowDropdownMenu}
        className="btn-circle-icon c-dropdown-menu__button"
      >
        <Icon name={showTabs ? "Close" : "Menu"} color={"white"} width={18} />
      </button>

      <div className={handleWrapperTabClasses()}>
        { tabs.map((tab, index) => (
          <p className={handleTabClasses(index)} onClick={() => handleChangeTab(tab, index)}>
            <span 
              className="c-dropdown-menu__content-tab-text" 
              style={{ minWidth: "100%", width: "100%", padding: "0 24px", justifyContent: "center" }}
            >
              {tab.title}
            </span>
          </p>
        ))}
      </div>
    </>
  )
  
}

export default ContentDropdownMenu;
