import { useState, useEffect, useRef } from 'react'
import { connect } from "react-redux";

// Actions
import { createAssetResource } from "../../actions/balance";
import {
  updateAutomaticLayout,
  getWealthMap,
  getListElement,
  createWildCardStructure,
  editElement,
  getPalettes,
  deleteNode,
  createNewNode
} from "../../actions/ownership";
import { getLinkForm } from "../../actions/formblock";
import GojsCanvas from './gojsCanvas';

const WealthMap = (props) => {

  useEffect(() => {
    const diagrams = document.querySelectorAll(".c-gojs__diagram")
    diagrams.forEach(diagram =>  {
      diagram.style.maxHeight = "initial"
      diagram.style.minHeight = "500px"
    })
  }, [])

  return (
    props.wealthMapData.graph.nodeDataArray.length > 0 && (
      <div className="main-wrapper ownership">
        <GojsCanvas
          isHome={true}
          isMobile={true}
          internalization={props.internalization}
          palettes={{}}
          ownership_structure={props.wealthMapData.graph}
          getListElement={(url, success_list, error_list) => null }
          createWildCardStructure={(object, success_create_wild_card, error_list) => null }
          deleteNode={(object, success_delete_noe, error_list) => null }
          editElement={(attributes, success_edit, error_edit) => null }
          getPalettes={(success_palettes) => null }
          isNew={false}
          location={props.location}
          history={props.history}
          fetch={() => null}
          inputs_creation={{}}
          showModalCreation={(data) => null}
          setDiagram={(diagram) => null }
          removeLastElement={false}
          updateAutomaticLayout={(value) => null}
          currentAutomaticLayout={props.user.ownership_automatic_layout_enabled}
          avatar={props.user.avatar_real_user}
        />
      </div>
    )

  )
}

function mapStateToProps(state) {
  return {
    permits: state.session.permits,    
    ownership_index: state.ownership.ownership_index,
    authentication_token: state.session.authentication_token,
    errors: state.session.errors,
    internalization: state.session.internalization,
    user: state.session.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateAutomaticLayout: (authentication_token, enabled, success, error) =>
      dispatch(updateAutomaticLayout(authentication_token, enabled, success, error)),
    deleteNode: (authentication_token, object, success, error) =>
      dispatch(deleteNode(authentication_token, object, success, error)),
    getPalettes: (authentication_token, success) =>
      dispatch(getPalettes(authentication_token, success)),
    editElement: (authentication_token, attributes, success, error) =>
      dispatch(editElement(authentication_token, attributes, success, error)),
    createWildCardStructure: (authentication_token, object, success, error) =>
      dispatch(createWildCardStructure(authentication_token, object, success, error)),
    getListElement: (authentication_token, class_name, success, error) =>
      dispatch(getListElement(authentication_token, class_name, success, error)),
    getLinkForm: (url, authentication_token, success, error) =>
      dispatch(getLinkForm(url, authentication_token, success, error)),
    getWealthMap: (authentication_token, success, error) =>
      dispatch(getWealthMap(authentication_token, success, error)),
    createAssetResource: (authentication_token, success, error, url, method, attributes) =>
      dispatch(createAssetResource(authentication_token, success, error, url, method, attributes)),
    createNewNode: (authentication_token, success, error, attributes) =>
      dispatch(createNewNode(authentication_token, success, error, attributes)),      
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(WealthMap);
